import { Button, Dropdown, Flex, Spin, Tooltip, notification } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import EscrowAccountsFilters from './EscrowAccountsFilters';
import { LuFileSearch2, LuSearch } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import {
	Account,
	AccountContent,
	AccountStatus,
	AccountType,
	EAccountStatusUpdate,
	IIUpdateAccountStatus,
} from 'types/Account';
import { AccountsService } from 'modules/escrow/services';
import dayjs from 'dayjs';
import {
	AccountStatusDescription,
	AccountStatusDisplay,
} from 'constants/account';
import { useCallback, useEffect, useState } from 'react';
import { Table } from 'components/Table';
import { FiltersContainer, IconWrapper } from './styles';
import { FilterButton } from 'components/FilterButton';
import { AiOutlineStop } from 'react-icons/ai';
import { useTheme } from 'styled-components';
import { EscrowAccountsFiltersType } from './types';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { ESortingPage, PaginationInfo } from 'types/List';
import { ETenantType, PersonType } from 'types/Company';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import { EmptyFiltersResult } from 'components/EmptyFiltersResult';
import { TextL, TextM, TextS } from 'components/Text';
import { StarIcon } from 'components/Icons/Star';
import { sortTableHandler } from 'helpers/tables';
import { normalizeBankAccount, normalizeCpfCnpj } from 'helpers/normalizers';
import { useEscrowAccountsContext } from 'modules/escrow/context/EscrowAccounts.context';
import { EscrowRoutes } from 'modules/escrow/constants/routes';
import ChipComponent from 'components/Chip/Chip';
import { MenuProps } from 'antd/lib';
import { ThumbUpIcon } from 'components/Icons/ThumbUp';
import { RiMailSendLine, RiRefreshLine } from 'react-icons/ri';
import { ThumbDownIcon } from 'components/Icons/ThumbDown';
import { HiOutlineCog6Tooth } from 'react-icons/hi2';
import { TrashIcon } from 'components/Icons/Trash';
import { LockIcon } from 'components/Icons/Lock';
import { UnlockIcon } from 'components/Icons/Unlock';
import { useAccountActions } from 'modules/escrow/hooks';
import { queryClient } from 'network/query';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { ApiError } from 'types/ApiError';
import { TEventConfirmationModal } from 'modules/escrow/pages/EscrowDetails/types';
import EventConfirmationModal from 'modules/escrow/pages/EscrowDetails/EventConfirmationModal/EventConfirmationModal';
import { ManagementService } from 'modules/management/services/management';

const EscrowAccounts = () => {
	/* Hooks */
	const theme = useTheme();
	const navigate = useNavigate();
	const [api, contextHolder] = notification.useNotification();
	const { setBreadcrumb } = useBreadcrumb();
	const { resetEscrowContext } = useEscrowAccountsContext();

	/* States */
	const [isAccountActionModalOpen, setIsAccountActionModalOpen] =
		useState<boolean>(false);
	const [isOpenConfirmationModal, setIsOpenConfirmationModal] =
		useState(false);
	const [eventInfoModal, setEventInfoModal] =
		useState<TEventConfirmationModal>({} as TEventConfirmationModal);
	const [modalInfo, setModalInfo] = useState({
		title: '',
		confirmText: '',
		action: () => {},
		children: <></>,
	});
	const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
	const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
		currentPage: 1,
		pageSize: 10,
	});
	const [sorting, setSorting] = useState<{
		order: ESortingPage;
		sort: string;
	}>({ order: ESortingPage.DESC, sort: 'created_at' });
	const [filters, setFilters] = useState<EscrowAccountsFiltersType>(
		{} as EscrowAccountsFiltersType,
	);

	const handleClearFilters = useCallback(() => {
		setFilters({} as EscrowAccountsFiltersType);
	}, []);

	const { data, isLoading } = useQuery({
		queryKey: ['accountsList', paginationInfo, filters, sorting],
		queryFn: () =>
			AccountsService.getAccounts({
				page: paginationInfo.currentPage,
				size: paginationInfo.pageSize,
				order: sorting.order,
				sort: sorting.sort,
				status: filters.status,
				search: filters.search,
				taxpayer_id: filters.taxpayer_id,
				account: filters.account,
				creator_name: filters.creator_name,
			}),
	});

	useEffect(() => {
		/* cleaning breadcrumb */
		setBreadcrumb(undefined);
		resetEscrowContext();
	}, [setBreadcrumb]);

	const { loading, block, cancel, retry, unblock } = useAccountActions({
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema.',
			});
		},
		onBlockSuccess: () => {
			queryClient.refetchQueries({
				queryKey: ['accountsList', paginationInfo],
			});
			setIsAccountActionModalOpen(false);
			api.success({
				description: `A conta foi bloqueada com sucesso.`,
				message: 'Conta bloqueada',
			});
		},
		onUnblockSuccess: () => {
			queryClient.refetchQueries({
				queryKey: ['accountsList', paginationInfo],
			});
			setIsAccountActionModalOpen(false);
			api.success({
				description: `A conta foi desbloqueada com sucesso.`,
				message: 'Conta desbloqueada',
			});
		},
		onCancelSuccess: () => {
			queryClient.refetchQueries({
				queryKey: ['accountsList', paginationInfo],
			});
			setIsAccountActionModalOpen(false);
		},
		onRetrySuccess: () => {
			queryClient.refetchQueries({
				queryKey: ['accountsList', paginationInfo],
			});
			api.success({
				description: `A conta foi recriada com sucesso.`,
				message: 'Conta recriada',
			});
		},
	});

	const { mutate: updateStatus } = useMutation<
		void,
		ApiError,
		IIUpdateAccountStatus
	>({
		mutationFn: ({ id, transition_event, reason }) => {
			return AccountsService.updateAccountStatus(id, {
				transition_event: transition_event,
				reason: reason,
			});
		},
		onSuccess: (_, data) => {
			if (
				data.transition_event ===
				EAccountStatusUpdate.BACKOFFICE_ANALYSIS_REQUEST_DOCUMENTS
			) {
				api.success({
					description:
						'A conta foi enviada para revisão de documentação.',
					message: 'Pendência sinalizada com sucesso!',
				});
			}
			if (
				data.transition_event ===
				EAccountStatusUpdate.BACKOFFICE_ANALYSIS_REFUSE
			) {
				api.success({
					description: 'A conta foi recusada em análise com sucesso.',
					message: 'Conta recusada com sucesso!',
				});
			}

			if (
				data.transition_event ===
				EAccountStatusUpdate.BACKOFFICE_ANALYSIS_APPROVE
			) {
				api.success({
					description:
						'A conta foi aprovada na análise e está sendo criada.',
					message: 'Conta aprovada com sucesso!',
				});
			}

			queryClient.refetchQueries({
				queryKey: ['accountsList', paginationInfo],
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema.',
			});
		},
	});

	const { mutate: retryAgreementMutation, isPending } = useMutation<
		void,
		ApiError,
		AccountContent | Account
	>({
		mutationFn: ({ id }) => {
			return AccountsService.retryAgreementAccount(id);
		},
		onSuccess: () => {
			queryClient.refetchQueries({
				queryKey: ['accountsList', paginationInfo],
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema.',
			});
		},
	});

	const {
		mutate: manualComplianceApproveMutation,
		isPending: isPendingApproveMutation,
	} = useMutation<void, ApiError, AccountContent | Account>({
		mutationFn: ({ id }) => {
			return ManagementService.manualComplianceApprove({
				accountId: id,
			});
		},
		onSuccess: () => {
			queryClient.refetchQueries({
				queryKey: ['accountsList', paginationInfo],
			});
			api.success({
				description:
					'A conta foi aprovada e seguirá o fluxo de criação de conta.',
				message: 'Conta aprovada',
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema.',
			});
		},
	});

	const accountHandler = (type: string, item: AccountContent) => {
		if (!item) {
			api.error({
				description: 'Atualize a página e tente novamente',
				message: 'Ocorreu um problema.',
			});
		}
		if (type === 'cancel') {
			return cancel(item);
		}

		if (type === 'block') {
			return block(item);
		}

		if (type === 'unblock') {
			return unblock(item);
		}

		if (type === 'retry') {
			return retry(item);
		}
	};

	const updateStatusHandler = (
		accountId: string,
		status: EAccountStatusUpdate,
		reason?: string,
	) => {
		if (
			status ===
			EAccountStatusUpdate.BACKOFFICE_ANALYSIS_REQUEST_DOCUMENTS
		) {
			updateStatus({
				id: accountId,
				transition_event:
					EAccountStatusUpdate.BACKOFFICE_ANALYSIS_REQUEST_DOCUMENTS,
				reason: reason || '',
			});
		}

		if (status === EAccountStatusUpdate.BACKOFFICE_ANALYSIS_REFUSE) {
			updateStatus({
				id: accountId,
				transition_event:
					EAccountStatusUpdate.BACKOFFICE_ANALYSIS_REFUSE,
				reason: reason || '',
			});
		}

		if (status === EAccountStatusUpdate.BACKOFFICE_ANALYSIS_APPROVE) {
			updateStatus({
				id: accountId,
				transition_event:
					EAccountStatusUpdate.BACKOFFICE_ANALYSIS_APPROVE,
				reason: reason || '',
			});
		}
	};

	const createActionItems = (item: AccountContent): MenuProps['items'] => {
		const defaultItems: MenuProps['items'] = [];

		defaultItems.push({
			label: 'Detalhes da conta',
			key: '0',
			onClick: () => {
				const route =
					item.account_type === AccountType.ESCROW
						? EscrowRoutes.ESCROW_BASE
						: EscrowRoutes.ACCOUNT_BASE;
				navigate(
					`${route}/${item.id}/${
						item.person.person_type === PersonType.LEGAL
							? 'details'
							: 'natural-person/details'
					}/info`,
				);
			},
			icon: (
				<IconWrapper>
					<LuFileSearch2 size={18} color={theme.textSecondary} />
				</IconWrapper>
			),
		});

		if ([AccountStatus.KYC_REFUSED].includes(item.status)) {
			defaultItems.push(
				{
					label: 'Aprovar conta manualmente',
					key: '1',
					onClick: () => {
						manualComplianceApproveMutation(item);
					},
					icon: (
						<IconWrapper>
							<ThumbUpIcon
								size={18}
								color={theme.textSecondary}
							/>
						</IconWrapper>
					),
				},
				{
					label: 'Reprovar conta',
					key: '2',
					onClick: () => {
						setEventInfoModal({
							buttonText: 'Rejeitar solicitação',
							modalTitle: 'Rejeitar solicitação',
							infoPrimary:
								'Você tem certeza que deseja reprovar a conta?',
							inputTextTitle: 'Motivo da rejeição',
							isDanger: true,
							disableIfEmptyReason: true,
							onClick: reason => {
								updateStatusHandler(
									item.id,
									EAccountStatusUpdate.BACKOFFICE_ANALYSIS_REFUSE,
									reason,
								);
								setIsOpenConfirmationModal(false);
							},
						});
						setIsOpenConfirmationModal(true);
					},
					icon: (
						<IconWrapper>
							<ThumbDownIcon
								size={18}
								color={theme.textSecondary}
							/>
						</IconWrapper>
					),
				},
			);
		}

		if ([AccountStatus.KYC_ERROR].includes(item.status)) {
			defaultItems.push({
				label: 'Reprovar conta',
				key: '1',
				onClick: () => {
					setEventInfoModal({
						buttonText: 'Rejeitar solicitação',
						modalTitle: 'Rejeitar solicitação',
						infoPrimary:
							'Você tem certeza que deseja reprovar a conta?',
						inputTextTitle: 'Motivo da rejeição',
						isDanger: true,
						disableIfEmptyReason: true,
						onClick: reason => {
							updateStatusHandler(
								item.id,
								EAccountStatusUpdate.BACKOFFICE_ANALYSIS_REFUSE,
								reason,
							);
							setIsOpenConfirmationModal(false);
						},
					});
					setIsOpenConfirmationModal(true);
				},
				icon: (
					<IconWrapper>
						<ThumbDownIcon size={18} color={theme.textSecondary} />
					</IconWrapper>
				),
			});
		}

		if (item.status === AccountStatus.AWAITING_BACKOFFICE_ANALYSIS) {
			defaultItems.push(
				{
					label: 'Iniciar análise',
					key: '1',
					onClick: () => {
						const route =
							item.account_type === AccountType.ESCROW
								? EscrowRoutes.ESCROW_BASE
								: EscrowRoutes.ACCOUNT_BASE;
						navigate(
							`${route}/${item.id}/${
								item.person.person_type === PersonType.LEGAL
									? 'details'
									: 'natural-person/details'
							}/info`,
						);
					},
					icon: (
						<IconWrapper>
							<LuFileSearch2
								size={18}
								color={theme.textSecondary}
							/>
						</IconWrapper>
					),
				},
				{
					label: 'Reprovar conta',
					key: '2',
					onClick: () => {
						setEventInfoModal({
							buttonText: 'Rejeitar solicitação',
							modalTitle: 'Rejeitar solicitação',
							infoPrimary:
								'Você tem certeza que deseja reprovar a conta?',
							inputTextTitle: 'Motivo da rejeição',
							isDanger: true,
							disableIfEmptyReason: true,
							onClick: reason => {
								updateStatusHandler(
									item.id,
									EAccountStatusUpdate.BACKOFFICE_ANALYSIS_REFUSE,
									reason,
								);
								setIsOpenConfirmationModal(false);
							},
						});
						setIsOpenConfirmationModal(true);
					},
					icon: (
						<IconWrapper>
							<ThumbDownIcon
								size={18}
								color={theme.textSecondary}
							/>
						</IconWrapper>
					),
				},
			);
		}

		if (item.status === AccountStatus.AWAITING_DOCUMENTS_UPDATE) {
			defaultItems.push({
				label: 'Reprovar conta',
				key: '1',
				onClick: () => {
					setEventInfoModal({
						buttonText: 'Rejeitar solicitação',
						modalTitle: 'Rejeitar solicitação',
						infoPrimary:
							'Você tem certeza que deseja reprovar a conta?',
						inputTextTitle: 'Motivo da rejeição',
						isDanger: true,
						disableIfEmptyReason: true,
						onClick: reason => {
							updateStatusHandler(
								item.id,
								EAccountStatusUpdate.BACKOFFICE_ANALYSIS_REFUSE,
								reason,
							);
							setIsOpenConfirmationModal(false);
						},
					});
					setIsOpenConfirmationModal(true);
				},
				icon: (
					<IconWrapper>
						<ThumbDownIcon size={18} color={theme.textSecondary} />
					</IconWrapper>
				),
			});
		}

		if (item.status === AccountStatus.BAAS_ACCOUNT_CREATION_ERROR) {
			defaultItems.push(
				{
					label: 'Reenviar conta para criação',
					key: '1',
					onClick: () => {
						accountHandler('retry', item);
					},
					icon: (
						<IconWrapper>
							<RiRefreshLine
								size={18}
								color={theme.textSecondary}
							/>
						</IconWrapper>
					),
				},
				{
					label: 'Reprovar conta',
					key: '2',
					onClick: () => {
						setEventInfoModal({
							buttonText: 'Rejeitar solicitação',
							modalTitle: 'Rejeitar solicitação',
							infoPrimary:
								'Você tem certeza que deseja rejeitar a abertura desta conta ?',
							inputTextTitle: 'Motivo da rejeição',
							isDanger: true,
							disableIfEmptyReason: true,
							onClick: reason => {
								updateStatusHandler(
									item.id,
									EAccountStatusUpdate.BACKOFFICE_ANALYSIS_REFUSE,
									reason,
								);
								setIsOpenConfirmationModal(false);
							},
						});
						setIsOpenConfirmationModal(true);
					},
					icon: (
						<IconWrapper>
							<ThumbDownIcon
								size={18}
								color={theme.textSecondary}
							/>
						</IconWrapper>
					),
				},
			);
		}

		if (item.status === AccountStatus.AGREEMENT_ERROR) {
			defaultItems.push(
				{
					label: 'Reprovar conta',
					key: '1',
					onClick: () => {
						setEventInfoModal({
							buttonText: 'Rejeitar solicitação',
							modalTitle: 'Rejeitar solicitação',
							infoPrimary: `Você tem certeza que deseja rejeitar a abertura da conta de ${item.person.name}?`,
							inputTextTitle: 'Motivo da rejeição',
							isDanger: true,
							disableIfEmptyReason: true,
							onClick: reason => {
								updateStatusHandler(
									item.id,
									EAccountStatusUpdate.BACKOFFICE_ANALYSIS_REFUSE,
									reason,
								);
								setIsOpenConfirmationModal(false);
							},
						});
						setIsOpenConfirmationModal(true);
					},
					icon: (
						<IconWrapper>
							<ThumbDownIcon
								size={18}
								color={theme.textSecondary}
							/>
						</IconWrapper>
					),
				},
				{
					label: 'Encerrar conta',
					key: '2',
					onClick: () => {
						setIsAccountActionModalOpen(true);
						setModalInfo({
							action: () => accountHandler('cancel', item),
							confirmText: 'Encerrar conta',
							title: 'Encerrar conta',
							children: (
								<>
									<TextL>
										Você tem certeza que deseja encerrar a
										conta?
									</TextL>
									<TextM weight="normal">
										Essa ação não poderá ser desfeita.
									</TextM>
								</>
							),
						});
					},
					icon: (
						<IconWrapper>
							<TrashIcon size={17} color={theme.textSecondary} />
						</IconWrapper>
					),
				},
			);
		}

		if (item.status === AccountStatus.SIGNATURE_ERROR) {
			defaultItems.push(
				{
					label: 'Reenviar e-mail de assinatura',
					key: '1',
					onClick: () => {
						retryAgreementMutation(item);
					},
					icon: (
						<IconWrapper>
							<RiMailSendLine
								size={18}
								color={theme.textSecondary}
							/>
						</IconWrapper>
					),
				},
				{
					label: 'Encerrar conta',
					key: '2',
					onClick: () => {
						setIsAccountActionModalOpen(true);
						setModalInfo({
							action: () => accountHandler('cancel', item),
							confirmText: 'Encerrar conta',
							title: 'Encerrar conta',
							children: (
								<>
									<TextL>
										Você tem certeza que deseja encerrar a
										conta?
									</TextL>
									<TextM weight="normal">
										Essa ação não poderá ser desfeita.
									</TextM>
								</>
							),
						});
					},
					icon: (
						<IconWrapper>
							<TrashIcon size={17} color={theme.textSecondary} />
						</IconWrapper>
					),
				},
			);
		}

		if (item.status === AccountStatus.ACTIVE) {
			defaultItems.push(
				{
					label: 'Configurações da conta',
					key: '1',
					onClick: () => {
						navigate(`/management/${item.id}/details`);
					},
					icon: (
						<IconWrapper>
							<HiOutlineCog6Tooth
								size={18}
								color={theme.textSecondary}
							/>
						</IconWrapper>
					),
				},
				{
					label: 'Encerrar conta',
					key: '2',
					onClick: () => {
						setIsAccountActionModalOpen(true);
						setModalInfo({
							action: () => accountHandler('cancel', item),
							confirmText: 'Encerrar conta',
							title: 'Encerrar conta',
							children: (
								<>
									<TextL>
										Você tem certeza que deseja encerrar a
										conta?
									</TextL>
									<TextM weight="normal">
										Essa ação não poderá ser desfeita.
									</TextM>
								</>
							),
						});
					},
					icon: (
						<IconWrapper>
							<TrashIcon size={17} color={theme.textSecondary} />
						</IconWrapper>
					),
				},
				{
					label: 'Bloquear conta',
					key: '3',
					onClick: () => {
						setIsAccountActionModalOpen(true);
						setModalInfo({
							action: () => accountHandler('block', item),
							confirmText: 'Bloquear conta',
							title: 'Bloquear conta',
							children: (
								<TextL>
									Você tem certeza que deseja bloquear a
									conta?
								</TextL>
							),
						});
					},
					icon: (
						<IconWrapper>
							<LockIcon size={18} color={theme.textSecondary} />
						</IconWrapper>
					),
				},
			);
		}

		if (item.status === AccountStatus.BLOCKED) {
			defaultItems.push(
				{
					label: 'Desbloquear',
					key: '1',
					onClick: () => {
						setIsAccountActionModalOpen(true);
						setModalInfo({
							action: () => accountHandler('unblock', item),
							confirmText: 'Desbloquear conta',
							title: 'Desbloquear conta',
							children: (
								<TextL>
									Você tem certeza que deseja desbloquear a
									conta?
								</TextL>
							),
						});
					},
					icon: (
						<IconWrapper>
							<UnlockIcon size={18} color={theme.textSecondary} />
						</IconWrapper>
					),
				},
				{
					label: 'Encerrar conta',
					key: '2',
					onClick: () => {
						setIsAccountActionModalOpen(true);
						setModalInfo({
							action: () => accountHandler('cancel', item),
							confirmText: 'Encerrar conta',
							title: 'Encerrar conta',
							children: (
								<>
									<TextL>
										Você tem certeza que deseja encerrar a
										conta?
									</TextL>
									<TextM weight="normal">
										Essa ação não poderá ser desfeita.
									</TextM>
								</>
							),
						});
					},
					icon: (
						<IconWrapper>
							<TrashIcon size={17} color={theme.textSecondary} />
						</IconWrapper>
					),
				},
			);
		}

		if (
			[
				AccountStatus.AWAITING_AGREEMENT_RENDERING,
				AccountStatus.AWAITING_AGREEMENT_RENDERING_PROCESSING,
				AccountStatus.AGREEMENT_RENDERED,
				AccountStatus.PENDING_SIGNATURE,
			].includes(item.status)
		) {
			defaultItems.push({
				label: 'Encerrar conta',
				key: '2',
				onClick: () => {
					setIsAccountActionModalOpen(true);
					setModalInfo({
						action: () => accountHandler('cancel', item),
						confirmText: 'Encerrar conta',
						title: 'Encerrar conta',
						children: (
							<>
								<TextL>
									Você tem certeza que deseja encerrar a
									conta?
								</TextL>
								<TextM weight="normal">
									Essa ação não poderá ser desfeita.
								</TextM>
							</>
						),
					});
				},
				icon: (
					<IconWrapper>
						<TrashIcon size={17} color={theme.textSecondary} />
					</IconWrapper>
				),
			});
		}
		return defaultItems;
	};

	const columns: ColumnsType<AccountContent> = [
		{
			title: 'Titular',
			sorter: true,
			key: 'company.name',
			render: (account: AccountContent) => (
				<div>
					<TextM weight="bold">{`${account.person.name}`}</TextM>
					<TextS weight="normal" color={theme.textSecondary}>
						{normalizeCpfCnpj(account.person.taxpayer_id)}
					</TextS>
				</div>
			),
			width: '20%',
		},
		{
			title: 'Conta',
			sorter: true,
			key: 'company.person_type',
			render: (account: AccountContent) => (
				<div>
					<TextM weight="normal">
						{account.account_type === AccountType.ESCROW
							? 'Escrow'
							: 'Livre'}
					</TextM>
				</div>
			),
			width: '4%',
		},
		{
			title: 'Data de abertura',
			width: '10%',
			sorter: true,
			key: 'created_at',
			render: (account: AccountContent) => (
				<div>
					<TextM weight="bold">{`${dayjs(account.created_at).format(
						'DD/MM/YYYY',
					)}`}</TextM>
					<TextS color={theme.textSecondary} weight="normal">
						{`às ${dayjs(account.created_at).format('HH:mm:ss')}`}
					</TextS>
				</div>
			),
		},
		{
			title: 'Criador',
			width: '15%',
			sorter: true,
			key: 'creator.fullName',
			render: (account: AccountContent) => (
				<div>
					<TextM weight="normal">
						{account?.creator.full_name || '-'}
					</TextM>
				</div>
			),
		},
		{
			title: 'Solicitante',
			width: '15%',
			sorter: true,
			key: 'requester_name',
			render: (account: AccountContent) => (
				<div>
					<TextM weight="normal">
						{account?.requester_name || '-'}
					</TextM>
				</div>
			),
		},
		{
			title: 'Nº da conta',
			width: '20%',
			sorter: true,
			key: 'account',
			render: (account: AccountContent) => (
				<Flex align="center">
					<div style={{ marginRight: '5px', width: '1.3rem' }}>
						{account.tenant_type !== ETenantType.DEFAULT && (
							<StarIcon size={20} />
						)}
					</div>
					<TextM weight="normal">
						{account.account
							? normalizeBankAccount(account.account)
							: '-'}
					</TextM>
				</Flex>
			),
		},
		{
			title: 'Status',
			width: '28%',
			render: (account: AccountContent) => {
				if (
					typeof account.error === 'string' &&
					[
						AccountStatus.AGREEMENT_ERROR,
						AccountStatus.BAAS_ACCOUNT_CREATION_ERROR,
						AccountStatus.BLOCKED,
						AccountStatus.KYC_ERROR,
						AccountStatus.KYC_REFUSED,
						AccountStatus.SIGNATURE_ERROR,
					].includes(account.status)
				)
					return (
						<ChipComponent
							alignText="flex-start"
							shadow={true}
							statusColor={
								AccountStatusDisplay[account.status]
									?.statusColor || theme.textGray
							}
							backgroundColor={
								AccountStatusDisplay[account.status]
									?.background || theme.grayLight
							}
						>
							<Tooltip title={account.error}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										gap: '8px',
									}}
								>
									<TextS
										color={
											AccountStatusDisplay[account.status]
												?.textColor || theme.textGray
										}
									>
										{AccountStatusDescription[
											account.status
										] || 'Status indisponível'}
									</TextS>
									<IoMdInformationCircleOutline
										size={16}
										color={
											AccountStatusDisplay[account.status]
												?.textColor || theme.textGray
										}
									/>
								</div>
							</Tooltip>
						</ChipComponent>
					);

				return (
					<ChipComponent
						alignText="flex-start"
						shadow
						statusColor={
							AccountStatusDisplay[account.status]?.statusColor ||
							theme.textGray
						}
						backgroundColor={
							AccountStatusDisplay[account.status]?.background ||
							theme.grayLight
						}
					>
						<TextS
							color={
								AccountStatusDisplay[account.status]
									?.textColor || theme.textGray
							}
						>
							{AccountStatusDescription[account.status] ||
								'Status indisponível'}
						</TextS>
					</ChipComponent>
				);
			},
		},
		{
			key: 'action',
			width: '3%',
			align: 'right',
			render: (account: AccountContent) => (
				<Dropdown
					disabled={isLoading}
					menu={{ items: createActionItems(account) }}
					trigger={['click']}
				>
					<Button
						type="link"
						loading={isLoading}
						style={{
							color: theme.text,
							fontWeight: 'bold',
							fontSize: '1.3rem',
							lineHeight: '1rem',
							letterSpacing: '0.09rem',
						}}
					>
						...
					</Button>
				</Dropdown>
			),
		},
	];

	return (
		<>
			{contextHolder}

			{(isLoading || isPending || isPendingApproveMutation) && (
				<Spin fullscreen />
			)}

			<FiltersContainer>
				<FilterButton
					icon={<LuSearch size={18} />}
					onClick={() => setIsFiltersOpen(true)}
				>
					<TextS style={{ color: theme.white }}>Busca avançada</TextS>
				</FilterButton>
				<FilterButton
					icon={<AiOutlineStop size={18} color={theme.white} />}
					variation="secondary"
					onClick={handleClearFilters}
					disabled={!Object.keys(filters).length}
				>
					<TextS style={{ color: theme.white }}>Limpar filtros</TextS>
				</FilterButton>
			</FiltersContainer>
			{data?.content?.length === 0 &&
			Object.values(filters).some(item => item !== undefined) ? (
				<EmptyFiltersResult
					title="Ops, sua busca não obteve nenhum resultado!"
					description={`Preencha os filtros avançados novamente ou limpe os filtros${'\n'}para encontrar o que você precisa.`}
				/>
			) : (
				<Table
					columns={columns}
					rowKey={record => record.id}
					dataSource={data?.content}
					loading={isLoading || isPending || isPendingApproveMutation}
					onChange={(_, __, sorter) =>
						sortTableHandler(sorter, setSorting)
					}
					pagination={{
						total: data?.total_elements || 0,
						showTotal(total) {
							const currentSize =
								paginationInfo.currentPage *
								paginationInfo.pageSize;
							return `${currentSize > total ? total : currentSize} de ${total}`;
						},
						pageSizeOptions: ['10', '30', '60', '90'],
						showSizeChanger: true,
						pageSize: paginationInfo.pageSize,
						current: paginationInfo.currentPage,
						onChange(page, pageSize) {
							setPaginationInfo({
								currentPage: page,
								pageSize,
							});
						},
					}}
				/>
			)}

			<EscrowAccountsFilters
				filters={filters}
				isOpen={isFiltersOpen}
				onClose={() => setIsFiltersOpen(false)}
				onApply={filters => {
					setFilters(filters);
					setIsFiltersOpen(false);
				}}
			/>

			<ConfirmationModal
				isOpen={isAccountActionModalOpen}
				danger={true}
				title={modalInfo.title}
				confirmText={modalInfo.confirmText}
				cancelText="Cancelar"
				isLoading={loading}
				onConfirm={modalInfo.action}
				onCancel={() => setIsAccountActionModalOpen(false)}
				onClose={() => setIsAccountActionModalOpen(false)}
			>
				{modalInfo.children}
			</ConfirmationModal>

			{isOpenConfirmationModal && (
				<EventConfirmationModal
					closeModal={() => setIsOpenConfirmationModal(false)}
					info={eventInfoModal}
					hasInputText={!!eventInfoModal?.inputTextTitle}
					isLoading={loading}
					confirmHandler={reason =>
						eventInfoModal?.onClick(reason || '')
					}
				/>
			)}
		</>
	);
};

export default EscrowAccounts;
