import { Button, notification } from 'antd';
import { BreadcrumbContainer } from 'components/Breadcrumb';
import { PageHeaderWithButton } from 'components/PageHeader/PageHeader';
import { TextM, TextS } from 'components/Text';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import { EscrowRoutes } from 'modules/escrow/constants/routes';
import {
	getAccountRouteTypeIcon,
	getAccountRouteTypeName,
	useIsEscrowAccountRoute,
} from 'modules/escrow/utils/adminRoute';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import theme from 'theme/theme';
import { AccountStatus } from 'types/Account';
import { EscrowBalance } from '../EscrowAccounts/styles';
import { useAccount } from 'modules/escrow/hooks/useAccount';
import { formatCurrency } from 'helpers/normalizers';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { useQuery } from '@tanstack/react-query';
import { AccountsService } from 'modules/escrow/services';
import { SplashScreen } from 'components/SplashScreen';
import PixEmptyState from './EmptyState/EmptyState';
import { FiltersContainer, StyledContainer, StyledTitle } from './Pix.styles';
import { LuPlusCircle, LuSearch } from 'react-icons/lu';
import { AbilityContext } from 'modules/core/context/AbilityContext';
import { subject } from '@casl/ability';
import { AccessType } from 'types/Access';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import PixTableComponent from './PixTable/PixTable';
import { IPix, TPixFilters } from './types';
import PixFiltersComponent from './PixFilters/PixFilters';
import { FilterButton } from 'components/FilterButton';
import { AiOutlineStop } from 'react-icons/ai';
import PixDetailsModal from './PixDetailsModal/PixDetailsModal';
import CreatePixModal from './CreatePixModal/CreatePixModal';
import PixReceipt from './PixReceipt/PixReceipt';

const PixPage = () => {
	const [api, contextHolder] = notification.useNotification();
	const { id: entityId } = useParams<{ id: string }>();
	const isEscrowRoute = useIsEscrowAccountRoute();
	const { setBreadcrumb } = useBreadcrumb();
	const { type } = useCurrentProfile();
	const ability = useContext(AbilityContext);

	const [isOpenFilters, setIsOpenFilters] = useState(false);
	const [filters, setFilters] = useState<TPixFilters>({} as TPixFilters);
	const [isOpenNewPixModal, setIsOpenNewPixModal] = useState(false);
	const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);
	const [isOpenReceiptModal, setIsOpenReceiptModal] = useState(false);
	const [pixIdSelected, setPixIdSelected] = useState<string>();
	const [visibleBalances, setVisibleBalances] = useState<{
		[key: string]: number;
	}>({});

	const redirectAccount = isEscrowRoute
		? EscrowRoutes.ESCROW_BASE
		: EscrowRoutes.ACCOUNT_BASE;
	const title = (
		<BreadcrumbContainer>
			{getAccountRouteTypeIcon()}
			<span>{getAccountRouteTypeName()}</span>
		</BreadcrumbContainer>
	);

	const { data, isLoading } = useQuery({
		queryKey: ['accountDetails', entityId],
		queryFn: () => AccountsService.getAccount(entityId!),
		refetchOnWindowFocus: false,
		enabled: typeof entityId === 'string',
	});

	const { data: pixData, isLoading: isLoadingPixData } = useQuery({
		queryKey: ['getPixCharges', entityId, filters],
		queryFn: () =>
			AccountsService.getPixCharges(entityId!, {
				end_date: filters?.end_date,
				start_date: filters?.start_date,
				status: filters?.status,
				taxpayer_id: filters?.taxpayer_id,
			}),
	});

	const { isBalanceLoading, getAccountBalance } = useAccount({
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema.',
			});
		},
		onBalanceSuccess: (account, amount) => {
			setVisibleBalances(prev => ({
				...prev,
				[account.id]: amount,
			}));
		},
	});

	useEffect(() => {
		setBreadcrumb([
			{
				href: redirectAccount,
				title: title,
			},
			{
				href: `${redirectAccount}/${entityId}/details`,
				title: (
					<TextM
						color={theme.textGray}
					>{`Conta ${data?.person?.name || ''}`}</TextM>
				),
			},
			{
				title: 'Cobrança Pix',
			},
		]);
	}, [data, redirectAccount, entityId]);

	const isVisible = typeof visibleBalances[data?.id || ''] === 'number';
	const hasPermission =
		type === AccessType.PERSON &&
		ability.can('CREATE', subject('CHARGE', { accountId: data?.id }));

	const handleClearFilters = useCallback(() => {
		setFilters({} as TPixFilters);
	}, []);

	const openDetailsHandler = (value: IPix) => {
		setPixIdSelected(value.id);
		setIsOpenDetailsModal(true);
	};

	if (isLoading) {
		return <SplashScreen />;
	}

	if (!data) {
		return (
			<Navigate
				to={
					isEscrowRoute
						? EscrowRoutes.ESCROW_BASE
						: EscrowRoutes.ACCOUNT_BASE
				}
			/>
		);
	}

	return (
		<div>
			{contextHolder}
			<PageHeaderWithButton
				title="Cobrar com pix"
				isSticky={true}
				hasDivider
			>
				{[AccountStatus.BLOCKED, AccountStatus.ACTIVE].includes(
					data.status,
				) && (
					<EscrowBalance isVisible={isVisible}>
						<TextM
							weight="bold"
							style={{
								marginRight: '0.6rem',
							}}
						>
							Saldo disponível:
						</TextM>
						<TextM weight="normal" color={theme.textGray}>
							{isVisible
								? formatCurrency(visibleBalances[data.id])
								: '*******'}
						</TextM>
						<Button
							type="link"
							size="large"
							icon={isVisible ? <BsEyeSlash /> : <BsEye />}
							loading={isBalanceLoading === entityId}
							onClick={() => {
								if (!isVisible) {
									getAccountBalance(data);
									return;
								}
								setVisibleBalances(prev => {
									delete prev[data.id];
									return { ...prev };
								});
							}}
						/>
					</EscrowBalance>
				)}
				{hasPermission && (
					<Button
						onClick={() => setIsOpenNewPixModal(true)}
						type="primary"
						style={{
							marginTop: '1rem',
							marginRight: '2rem',
							padding: '1.3rem',
							marginBottom: '1rem',
						}}
					>
						<TextM color={theme.white}>Criar nova cobrança</TextM>
						<LuPlusCircle
							style={{ marginLeft: '0.6rem' }}
							size={18}
						/>
					</Button>
				)}
			</PageHeaderWithButton>

			<StyledContainer>
				<StyledTitle>Minhas cobranças</StyledTitle>

				<FiltersContainer>
					<FilterButton
						icon={<LuSearch size={18} />}
						onClick={() => setIsOpenFilters(true)}
					>
						<TextS color={theme.white}>Busca avançada</TextS>
					</FilterButton>
					<FilterButton
						icon={<AiOutlineStop size={18} color={theme.white} />}
						variation="secondary"
						onClick={handleClearFilters}
						disabled={!Object.keys(filters).length}
					>
						<TextS style={{ color: theme.white }}>
							Limpar filtros
						</TextS>
					</FilterButton>
				</FiltersContainer>

				{!isLoading &&
					(!!pixData?.content.length ? (
						<PixTableComponent
							pixData={pixData}
							setIsOpenDetails={openDetailsHandler}
							isLoading={isLoadingPixData}
						/>
					) : (
						<PixEmptyState
							addNewPixHandler={() => setIsOpenNewPixModal(true)}
							hasPermission={hasPermission}
						/>
					))}
			</StyledContainer>

			{isOpenFilters && (
				<PixFiltersComponent
					isOpen={isOpenFilters}
					onClose={() => setIsOpenFilters(false)}
					filters={filters}
					onApply={filters => {
						setFilters(filters);
						setIsOpenFilters(false);
					}}
				/>
			)}

			{isOpenDetailsModal && pixIdSelected && (
				<PixDetailsModal
					closeModal={() => setIsOpenDetailsModal(false)}
					pixId={pixIdSelected}
					redirectReceipt={() => setIsOpenReceiptModal(true)}
				/>
			)}

			{isOpenNewPixModal && (
				<CreatePixModal
					closeModal={() => setIsOpenNewPixModal(false)}
					pixCreated={id => {
						setIsOpenNewPixModal(false);
						setPixIdSelected(id);
						setIsOpenDetailsModal(true);
					}}
				/>
			)}

			{isOpenReceiptModal && pixIdSelected && (
				<PixReceipt
					isOpen={isOpenReceiptModal}
					onClose={() => setIsOpenReceiptModal(false)}
					pixId={pixIdSelected}
					account={data}
				/>
			)}
		</div>
	);
};

export default PixPage;
