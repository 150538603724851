import styled from 'styled-components';

export const StyledPixDetails = styled.div`
	background-color: ${({ theme }) => theme.background};
	display: flex;
	flex-direction: column;
	padding: 1rem;
	border-radius: 5px;
	height: 35rem;
	overflow-y: auto;
	overflow-x: hidden;
	align-items: center;
	width: 100%;
`;

export const StyledFooter = styled.div`
	margin-top: 1rem;
	display: flex;
	justify-content: flex-end;
`;

export const StyledChipWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	max-height: 1.5rem;
	width: 100%;
`;

export const StyledButtons = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	margin-bottom: 1rem;

	:first-child {
		margin-right: 1rem;
	}
`;

export const StyledQrCode = styled.div`
	width: 13rem;
	height: 13rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 1rem;
`;
