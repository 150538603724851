import styled from 'styled-components';

export const StyledTitle = styled.p`
	font-family: Urbanist;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 32px;
`;

export const StyledContainer = styled.div`
	padding: 2rem;
`;

export const FiltersContainer = styled.div`
	display: flex;
	gap: 1rem;
	margin-bottom: 1rem;
`;
