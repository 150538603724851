import { Form } from 'antd';
import styled from 'styled-components';
import { TPixFilters } from '../types';

export const FiltersForm = styled(Form<TPixFilters>)`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 0.7rem;
	flex-wrap: wrap;
`;

export const FiltersFormItem = styled(Form.Item)`
	label {
		font-weight: bold;
		color: ${p => p.theme.text};
	}
`;
