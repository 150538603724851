import { IPix, pixDisplay } from '../types';
import { Table } from 'components/Table';
import { TextS } from 'components/Text';
import theme from 'theme/theme';
import { useState } from 'react';
import { List, PaginationInfo } from 'types/List';
import { formatCurrency, normalizeCpfCnpj } from 'helpers/normalizers';
import dayjs from 'dayjs';
import { BsEye } from 'react-icons/bs';
import ChipComponent from 'components/Chip/Chip';

interface IPixTableComponentProps {
	pixData?: List<IPix>;
	setIsOpenDetails: (pix: IPix) => void;
	isLoading: boolean;
}

const PixTableComponent = ({
	pixData,
	setIsOpenDetails,
	isLoading,
}: IPixTableComponentProps) => {
	const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
		currentPage: 1,
		pageSize: 10,
	});

	const columns: ColumnsType<IPix> = [
		{
			title: 'CPF do devedor',
			sorter: false,
			key: 'tax_id',
			width: '25%',
			render: (pix: IPix) => (
				<div key={pix.id}>
					<TextS>{normalizeCpfCnpj(pix.debtor.taxpayer_id)}</TextS>
				</div>
			),
		},
		{
			title: 'Valor',
			width: '25%',
			sorter: false,
			key: 'amount',
			render: (pix: IPix) => (
				<div key={pix.id}>
					<TextS>
						{formatCurrency(parseFloat(pix.amount.toString()))}
					</TextS>
				</div>
			),
		},

		{
			title: 'Data de vencimento',
			width: '25%',
			sorter: false,
			key: 'activated_at',
			render: (pix: IPix) => (
				<div key={pix.id}>
					<TextS>{dayjs(pix.due_date).format('DD/MM/YYYY')}</TextS>
				</div>
			),
		},
		{
			title: 'Status',
			width: '20%',
			sorter: false,
			render: (pix: IPix) => {
				return (
					<ChipComponent
						key={pix.id}
						alignText="center"
						square={true}
						backgroundColor={
							pixDisplay[pix.status]?.background ||
							theme.grayLight
						}
					>
						<TextS
							weight="bold"
							color={
								pixDisplay[pix.status]?.textColor ||
								theme.textGray
							}
						>
							{pixDisplay[pix.status]?.label ||
								'Status indisponível'}
						</TextS>
					</ChipComponent>
				);
			},
		},
		{
			title: 'Ação',
			width: '5%',
			sorter: false,
			key: 'id',
			render: (pix: IPix) => (
				<div key={pix.id}>
					<TextS color={theme.primary}>
						<BsEye />
					</TextS>
				</div>
			),
		},
	];

	return (
		<>
			<Table
				scroll={{ x: 1000 }}
				columns={columns}
				rowKey={record => record.id}
				dataSource={pixData?.content}
				loading={isLoading}
				onRow={record => {
					return {
						onClick: () => {
							setIsOpenDetails(record);
						},
					};
				}}
				pagination={{
					total: pixData?.total_elements || 0,
					showTotal(total) {
						const currentSize =
							paginationInfo.currentPage *
							paginationInfo.pageSize;
						return `${currentSize > total ? total : currentSize} de ${total}`;
					},
					pageSizeOptions: ['10', '30', '60', '90'],
					showSizeChanger: true,
					pageSize: paginationInfo.pageSize,
					current: paginationInfo.currentPage,
					onChange(page, pageSize) {
						setPaginationInfo({
							currentPage: page,
							pageSize,
						});
					},
				}}
				style={{
					cursor: 'pointer',
				}}
			/>
		</>
	);
};

export default PixTableComponent;
