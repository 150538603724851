import { Table as TableAnt } from 'antd';
import styled from 'styled-components';

export const Table = styled(TableAnt)<{ borderless?: boolean }>`
	border: ${p => (p.borderless ? 'none' : `1px solid ${p.theme.border}`)};
	border-radius: 10px;

	thead {
		background-color: ${p => p.theme.backgroundTable};
	}
	.ant-pagination-item-active {
		border-color: ${p => p.theme.primaryOpacity};
	}

	.ant-pagination {
		padding: 0 1rem;
		align-items: center;
	}

	.ant-pagination-total-text {
		margin-right: auto;
	}

	.ant-table-row:last-of-type {
		.ant-table-cell {
			border-bottom: ${p =>
				p.borderless ? 'none' : `1px solid #f0f0f0`};
		}
	}

	.ant-table-selection-column {
		padding-inline-end: 5px !important;
		padding-inline-start: 20px !important;

		.ant-checkbox:not(.ant-checkbox-indeterminate) {
			.ant-checkbox-inner {
				width: 24px !important;
				height: 24px !important;
				border-radius: 5px !important;

				&::after {
					width: 7px !important;
					height: 12px !important;
					top: 47% !important;
					inset-inline-start: 30% !important;
				}
			}
		}
		.ant-checkbox-indeterminate {
			.ant-checkbox-inner {
				width: 24px !important;
				height: 24px !important;
				border-radius: 5px !important;

				&::after {
					width: 13px !important;
					height: 13px !important;
				}
			}
		}
	}
`;
