import styled from 'styled-components';

export const CargeSelectWrapper = styled.div`
	border-top: 1rem;
	display: flex;
	width: 100%;

	.ant-select-selector {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
`;
