import { useParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import {
	Button,
	Divider,
	Flex,
	Skeleton,
	Switch,
	Upload,
	notification,
} from 'antd';
import { useMutation } from '@tanstack/react-query';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { saveAs } from 'file-saver';

// Components
import { AddCircleIcon } from 'components/Icons/AddCircle';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { FeeDescriptions } from 'components/FeeDescriptions';
import { FileCheckLine } from 'components/Icons/FileCheckLine';
import { Heading1 } from 'components/Heading1';
import { Heading2 } from 'components/Heading2';
import { TextM, TextS } from 'components/Text';
import ActionsButtonsComponent from './ActionButtons/ActionsButtons';
import EmptyConfigComponent from './EmptyConfig/EmptyConfig';
import FeeSettingsModalComission from 'components/FeeSettingsModalComission/FeeSettingsModalCommission';
import SignerMethodModal from './SignerMethodModal/SignerMethodModal';

// Hooks and services
import { ManagementService } from 'modules/management/services/management';
import { UploadService } from 'modules/core/services/UploadService';

// Types and helpers
import {
	AccountFee,
	AccountFeeMode,
	AccountFeeType,
	AccountType,
} from 'types/Account';
import { ApiError } from 'types/ApiError';
import {
	ClicksignSignatureMethodType,
	IAccountFeeParsed,
	ICreateUpdateConfigProps,
	ITemplateSkipSignatureContract,
	ITemplateConfig,
	ITemplateConfigAccountFee,
	ITemplateConfigCommission,
	ITemplateConfigCommissionPayload,
	ITemplateConfigEscrowAgreement,
	ITemplateConfigEscrowAgreementPayload,
	ITemplateConfigPaymentAgreement,
	ITemplateConfigSignature,
	ITemplateConfigSignaturePayload,
	TemplateType,
	ITemplateSkipSignatureContractPayload,
} from './types';

// Styles
import theme from 'theme/theme';
import { StyledMethods } from './TemplateConfig.styles';
import { List } from 'types/List';
import { queryClient } from 'network/query';
import { PaymentType } from 'types/Posting';
import { Collapse, CollapseHeader } from 'components/Collapse';

export const signMethodOptions = ['E-mail', 'Selfie', 'Certificado digital'];

const TemplateConfigContainer = () => {
	/* Hooks */
	const [api, contextHolder] = notification.useNotification();
	const { id } = useParams();

	/* States */
	const [selectedMethod, setSelectedMethod] = useState<TemplateType>();
	const [signMethods, setSignMethods] = useState<string[]>(
		signMethodOptions.filter(item => item !== 'Certificado digital'),
	);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
	const [isOpenSignerMethodPayment, setIsOpenSignerMethodPayment] =
		useState<boolean>(false);
	const [isOpenSignerMethodEscrow, setIsOpenSignerMethodEscrow] =
		useState<boolean>(false);
	const [isOpenChargeConfigModal, setIsOpenChargeConfigModal] =
		useState<boolean>(false);
	const [isOpenChargeConfigModalPayment, setIsOpenChargeConfigModalPayment] =
		useState<boolean>(false);

	/* Vars */
	const companyId: string | undefined = id;
	const clicksignSignatureMethodDisplayName: Record<
		ClicksignSignatureMethodType,
		string
	> = {
		[ClicksignSignatureMethodType.EMAIL_TOKEN]: 'E-mail',
		[ClicksignSignatureMethodType.SELFIE]: 'Selfie',
		[ClicksignSignatureMethodType.ICP_CERTIFICATE]: 'Certificado',
	};

	/* Api calls */
	const { data, isLoading: isLoadingData } = useQuery<
		List<ITemplateConfig>,
		Error
	>({
		queryKey: ['templateConfig', companyId],
		queryFn: () =>
			ManagementService.getTemplateConfig({
				person_id: companyId,
			}),
		refetchOnWindowFocus: false,
	} as UseQueryOptions<List<ITemplateConfig>, Error>);

	const { mutate: createConfig, isPending: isPendingCreate } = useMutation<
		void,
		ApiError,
		{
			payload:
				| ITemplateConfigEscrowAgreementPayload
				| ITemplateConfigPaymentAgreement
				| ITemplateConfigCommissionPayload
				| ITemplateConfigSignaturePayload
				| ITemplateSkipSignatureContractPayload;
		}
	>({
		mutationFn: ({ payload }) => {
			return ManagementService.createTemplateConfig(payload);
		},
		onSuccess: () => {
			closeAndClearHandler();

			if (
				selectedMethod === TemplateType.SKIP_AGREEMENT_ESCROW ||
				selectedMethod === TemplateType.SKIP_AGREEMENT_PAYMENT
			) {
				api.success({
					message: 'Fluxo de contrato alterado!',
				});
				queryClient.invalidateQueries({
					queryKey: ['templateConfig', companyId],
				});
				return;
			}

			api.success({
				message: 'Configuração criada com sucesso!',
			});
			queryClient.invalidateQueries({
				queryKey: ['templateConfig', companyId],
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um erro ao tentar criar a configuração',
			});
		},
	});

	const { mutate: deleteConfig, isPending: isPendingDelete } = useMutation<
		void,
		ApiError,
		{
			configId: string;
		}
	>({
		mutationFn: ({ configId }) => {
			return ManagementService.deleteTemplateConfig(configId);
		},
		onSuccess: () => {
			closeAndClearHandler();

			if (
				selectedMethod === TemplateType.SKIP_AGREEMENT_ESCROW ||
				selectedMethod === TemplateType.SKIP_AGREEMENT_PAYMENT
			) {
				api.success({
					message: 'Fluxo de contrato alterado!',
				});
				queryClient.invalidateQueries({
					queryKey: ['templateConfig', companyId],
				});
				return;
			}

			api.success({
				message: 'Configuração removida com sucesso!',
			});

			queryClient.invalidateQueries({
				queryKey: ['templateConfig', companyId],
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um erro ao tentar deletar a configuração',
			});
		},
	});

	const { mutate: updateConfig, isPending: isPendingUpdate } = useMutation<
		void,
		ApiError,
		{
			configId: string;
			payload:
				| ITemplateConfigEscrowAgreementPayload
				| ITemplateConfigPaymentAgreement
				| ITemplateConfigCommissionPayload
				| ITemplateConfigSignaturePayload;
		}
	>({
		mutationFn: ({ configId, payload }) => {
			return ManagementService.updateTemplateConfig(configId, payload);
		},
		onSuccess: () => {
			closeAndClearHandler();

			api.success({
				message: 'Configuração editada com sucesso',
			});

			queryClient.invalidateQueries({
				queryKey: ['templateConfig', companyId],
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um erro ao tentar editar a configuração',
			});
		},
	});

	const isLoading =
		isPendingUpdate || isPendingDelete || isLoadingData || isPendingCreate;
	const isLoadingFile = false;
	const isLoadingPaymentFile = false;

	// Charges [ESCROW]
	const filteredCharges: ITemplateConfigCommission[] | undefined =
		data?.content.map(item =>
			item.template_type === TemplateType.FEE_AND_COMMISSION_ESCROW
				? (item as unknown as ITemplateConfigCommission)
				: ({} as ITemplateConfigCommission),
		);
	const chargeDataPerson = filteredCharges?.filter(
		item => item.configuration_payload && item.person_id,
	)[0];
	const chargeDataGlobal = filteredCharges?.filter(
		item => item.configuration_payload && !item.person_id,
	)[0];
	const chargeData: ITemplateConfigCommission | undefined = companyId
		? chargeDataPerson
		: chargeDataGlobal;

	// Charges [PAYMENT]
	const filteredChargesPayment: ITemplateConfigCommission[] | undefined =
		data?.content.map(item =>
			item.template_type === TemplateType.FEE_AND_COMMISSION_PAYMENT
				? (item as unknown as ITemplateConfigCommission)
				: ({} as ITemplateConfigCommission),
		);
	const chargeDataPersonPayment = filteredChargesPayment?.filter(
		item => item.configuration_payload && item.person_id,
	)[0];
	const chargeDataGlobalPayment = filteredChargesPayment?.filter(
		item => item.configuration_payload && !item.person_id,
	)[0];
	const chargeDataPayment: ITemplateConfigCommission | undefined = companyId
		? chargeDataPersonPayment
		: chargeDataGlobalPayment;

	// Signatures [ESCROW]
	const filteredSignatures: ITemplateConfigSignature[] | undefined =
		data?.content.map(item =>
			item.template_type === TemplateType.SIGNATURE_METHOD_ESCROW
				? (item as unknown as ITemplateConfigSignature)
				: ({} as ITemplateConfigSignature),
		);

	const signatureDataPerson = filteredSignatures?.filter(
		item => item.configuration_payload && item.person_id,
	)[0];
	const signatureDataGlobal = filteredSignatures?.filter(
		item => item.configuration_payload && !item.person_id,
	)[0];
	const signatureData: ITemplateConfigSignature | undefined = companyId
		? signatureDataPerson
		: signatureDataGlobal;
	const methods =
		signatureData &&
		(signatureData?.configuration_payload
			?.methods as ClicksignSignatureMethodType[]);

	const getMethodDisplayName = (method: ClicksignSignatureMethodType) => {
		return (
			<Flex align="center">
				<TextS style={{ margin: '0 0.5rem' }}>
					{clicksignSignatureMethodDisplayName[method] ||
						'Método desconhecido'}
				</TextS>
				<Divider
					type="vertical"
					style={{ backgroundColor: theme.text }}
				/>
			</Flex>
		);
	};

	// Signatures [PAYMENT]
	const filteredSignaturesPayment: ITemplateConfigSignature[] | undefined =
		data?.content.map(item =>
			item.template_type === TemplateType.SIGNATURE_METHOD_PAYMENT
				? (item as unknown as ITemplateConfigSignature)
				: ({} as ITemplateConfigSignature),
		);

	const signatureDataPersonPayment = filteredSignaturesPayment?.filter(
		item => item.configuration_payload && item.person_id,
	)[0];
	const signatureDataGlobalPayment = filteredSignaturesPayment?.filter(
		item => item.configuration_payload && !item.person_id,
	)[0];
	const signatureDataPayment: ITemplateConfigSignature | undefined = companyId
		? signatureDataPersonPayment
		: signatureDataGlobalPayment;
	const methodsPayment =
		signatureDataPayment &&
		(signatureDataPayment?.configuration_payload
			?.methods as ClicksignSignatureMethodType[]);

	// Skip Contract Signatures [ESCROW]
	const skipContractEscrow: ITemplateSkipSignatureContract[] | undefined =
		data?.content.map(item =>
			item.template_type === TemplateType.SKIP_AGREEMENT_ESCROW
				? (item as unknown as ITemplateSkipSignatureContract)
				: ({} as ITemplateSkipSignatureContract),
		);
	const skipContractSignaturesEscrow = skipContractEscrow?.filter(
		item => item.id && item.person_id,
	)[0];

	// Skip Contract Signatures [PAYMENT]
	const skipContractPayment: ITemplateSkipSignatureContract[] | undefined =
		data?.content.map(item =>
			item.template_type === TemplateType.SKIP_AGREEMENT_PAYMENT
				? (item as unknown as ITemplateSkipSignatureContract)
				: ({} as ITemplateSkipSignatureContract),
		);
	const skipContractSignaturesPayment = skipContractPayment?.filter(
		item => item.id && item.person_id,
	)[0];

	// Contract [ESCROW]
	const props = {
		beforeUpload: (file: File) => {
			const isDocx =
				file.type ===
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

			if (!isDocx) {
				return api.error({
					description: 'Selecione um documento do tipo docx',
					message: 'Formato inválido',
				});
			}

			createConfigHandler({
				method: TemplateType.ESCROW_ACCOUNT_AGREEMENT,
				file: file,
			});
			return false;
		},
		showUploadList: false,
	};
	const filteredEscrowContract: ITemplateConfigEscrowAgreement[] | undefined =
		data?.content.map(item =>
			item.template_type === TemplateType.ESCROW_ACCOUNT_AGREEMENT
				? (item as unknown as ITemplateConfigEscrowAgreement)
				: ({} as ITemplateConfigEscrowAgreement),
		);

	const escrowContractPerson = filteredEscrowContract?.filter(
		item => item.id && item.person_id,
	)[0];
	const escrowContractGlobal = filteredEscrowContract?.filter(
		item => item.id && !item.person_id,
	)[0];
	const escrowContractData: ITemplateConfigEscrowAgreement | undefined =
		companyId ? escrowContractPerson : escrowContractGlobal;

	// Contract [PAYMENT]
	const propsPayment = {
		beforeUpload: (file: File) => {
			const isDocx =
				file.type ===
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

			if (!isDocx) {
				return api.error({
					description: 'Selecione um documento do tipo docx',
					message: 'Formato inválido',
				});
			}

			createConfigHandler({
				method: TemplateType.PAYMENT_ACCOUNT_AGREEMENT,
				file: file,
			});
			return false;
		},
		showUploadList: false,
	};
	const filteredPaymentContract:
		| ITemplateConfigPaymentAgreement[]
		| undefined = data?.content.map(item =>
		item.template_type === TemplateType.PAYMENT_ACCOUNT_AGREEMENT
			? (item as unknown as ITemplateConfigPaymentAgreement)
			: ({} as ITemplateConfigPaymentAgreement),
	);

	const paymentContractPerson = filteredPaymentContract?.filter(
		item => item.id && item.person_id,
	)[0];
	const paymentContractGlobal = filteredPaymentContract?.filter(
		item => item.id && !item.person_id,
	)[0];
	const paymentContractData: ITemplateConfigPaymentAgreement | undefined =
		companyId ? paymentContractPerson : paymentContractGlobal;

	const closeAndClearHandler = () => {
		setSelectedMethod(undefined);
		setIsOpenDeleteModal(false);
		setIsOpenSignerMethodEscrow(false);
		setIsOpenSignerMethodPayment(false);
		setIsOpenChargeConfigModal(false);
		setIsOpenChargeConfigModalPayment(false);
		setIsEditing(false);
	};

	const createConfigHandler = useCallback(
		async (props: ICreateUpdateConfigProps) => {
			if (!selectedMethod && !props.method) {
				return api.error({
					description:
						'Por favor atualize a página e tente novamente',
					message: 'Houve um erro',
				});
			}

			if (props.method === TemplateType.SKIP_AGREEMENT_ESCROW) {
				createConfig({
					payload: {
						configurationPayload: {
							enabled: true,
						},
						templateType: TemplateType.SKIP_AGREEMENT_ESCROW,
						personId: companyId || undefined,
					} as ITemplateSkipSignatureContractPayload,
				});
			}

			if (props.method === TemplateType.SKIP_AGREEMENT_PAYMENT) {
				createConfig({
					payload: {
						configurationPayload: {
							enabled: true,
						},
						templateType: TemplateType.SKIP_AGREEMENT_PAYMENT,
						personId: companyId || undefined,
					} as ITemplateSkipSignatureContractPayload,
				});
			}

			if (
				selectedMethod === TemplateType.SIGNATURE_METHOD_PAYMENT ||
				props.method === TemplateType.SIGNATURE_METHOD_PAYMENT
			) {
				const parseMethod = [];

				if (!signMethods.length) {
					return api.error({
						description: 'Selecione ao menos uma opção',
						message: 'Campos vazios',
					});
				}

				if (signMethods.includes('E-mail')) {
					parseMethod.push(ClicksignSignatureMethodType.EMAIL_TOKEN);
				}
				if (signMethods.includes('Selfie')) {
					parseMethod.push(ClicksignSignatureMethodType.SELFIE);
				}
				if (signMethods.includes('Certificado digital')) {
					parseMethod.push(
						ClicksignSignatureMethodType.ICP_CERTIFICATE,
					);
				}

				createConfig({
					payload: {
						configurationPayload: {
							methods: parseMethod,
						},
						templateType: TemplateType.SIGNATURE_METHOD_PAYMENT,
						personId: companyId || undefined,
					},
				});
			}

			if (
				selectedMethod === TemplateType.SIGNATURE_METHOD_ESCROW ||
				props.method === TemplateType.SIGNATURE_METHOD_ESCROW
			) {
				const parseMethod = [];

				if (!signMethods.length) {
					return api.error({
						description: 'Selecione ao menos uma opção',
						message: 'Campos vazios',
					});
				}

				if (signMethods.includes('E-mail')) {
					parseMethod.push(ClicksignSignatureMethodType.EMAIL_TOKEN);
				}
				if (signMethods.includes('Selfie')) {
					parseMethod.push(ClicksignSignatureMethodType.SELFIE);
				}
				if (signMethods.includes('Certificado digital')) {
					parseMethod.push(
						ClicksignSignatureMethodType.ICP_CERTIFICATE,
					);
				}

				createConfig({
					payload: {
						configurationPayload: {
							methods: parseMethod,
						},
						templateType: TemplateType.SIGNATURE_METHOD_ESCROW,
						personId: companyId || undefined,
					},
				});
			}

			if (
				selectedMethod === TemplateType.FEE_AND_COMMISSION_ESCROW ||
				props.method === TemplateType.FEE_AND_COMMISSION_ESCROW
			) {
				if (!props.fees) {
					return api.error({
						description: 'Preencha corretamente as tarifas',
						message: 'Houve um erro',
					});
				}

				const fees: AccountFee = props.fees as AccountFee;
				const parsedFees: IAccountFeeParsed =
					props.fees as IAccountFeeParsed;
				const withDestinationPayload: ITemplateConfigAccountFee = {
					beneficiary: props.destination?.taxpayer_id
						? {
								taxpayer_id: props.destination.taxpayer_id,
								name: props.destination.name,
								bank: props.destination.bank,
								branch: props.destination.branch.toString(),
								account: props.destination.account,
								type: props.destination.type,
							}
						: undefined,
					mode: (parsedFees.feeMode as AccountFeeMode) || fees.mode,
					default_payment_type: PaymentType.PIX,
					// TARIFA DA CONTA:
					maintenance: {
						day: parsedFees.maintenanceDay,
						amount: parsedFees?.maintenanceAmount || 0,
						commission_percent_amount:
							parsedFees?.commissionMaintenanceAmount,
					},
					// PIX ENTRADA:
					deposit: {
						amount_type:
							(parsedFees?.depositType as AccountFeeType) ||
							AccountFeeType.FIXED,
						amount: parsedFees.deposit || 0,
					},
					// PIX SAIDA:
					withdrawal: {
						amount_type:
							(parsedFees?.pixDebitType as AccountFeeType) ||
							AccountFeeType.FIXED,
						amount: parsedFees?.pixDebit || 0,
						commission_percent_amount:
							parsedFees?.commissionWithdrawalAmount,
					},
					// TED SAIDA:
					ted_transfer: {
						amount_type:
							(parsedFees?.tedDebitType as AccountFeeType) ||
							AccountFeeType.FIXED,
						amount: parsedFees?.tedDebit || 0,
						commission_percent_amount:
							parsedFees?.commissionTedTransferAmount,
					},
					// TARIFA DE BOLETO:
					charge_settlement: {
						amount_type: AccountFeeType.FIXED,
						amount: parsedFees?.chargeSettlement || 0,
						commission_percent_amount:
							parsedFees?.commissionChargeSettlementAmount,
					},
					// COBRANÇA PIX:
					charge_pix: {
						amount_type: AccountFeeType.FIXED,
						amount: parsedFees?.chargePix || 0,
						commission_percent_amount:
							parsedFees?.commissionChargePixAmount,
					},
					//EMISSÃO DE BOLETO:
					charge_issuance: {
						amount_type: AccountFeeType.FIXED,
						amount: parsedFees?.chargeIssuance || 0,
						commission_percent_amount:
							parsedFees?.commissionChargeIssuanceAmount,
					},
				};

				createConfig({
					payload: {
						configurationPayload: props.destination
							? withDestinationPayload
							: props.fees,
						templateType: TemplateType.FEE_AND_COMMISSION_ESCROW,
						personId: companyId || undefined,
					} as ITemplateConfigCommissionPayload,
				});
			}

			if (
				selectedMethod === TemplateType.FEE_AND_COMMISSION_PAYMENT ||
				props.method === TemplateType.FEE_AND_COMMISSION_PAYMENT
			) {
				if (!props.fees) {
					return api.error({
						description: 'Preencha corretamente as tarifas',
						message: 'Houve um erro',
					});
				}

				const fees: AccountFee = props.fees as AccountFee;
				const parsedFees: IAccountFeeParsed =
					props.fees as IAccountFeeParsed;
				const withDestinationPayload: ITemplateConfigAccountFee = {
					beneficiary: props.destination?.taxpayer_id
						? {
								taxpayer_id: props.destination.taxpayer_id,
								name: props.destination.name,
								bank: props.destination.bank,
								branch: props.destination.branch.toString(),
								account: props.destination.account,
								type: props.destination.type,
							}
						: undefined,
					mode: (parsedFees.feeMode as AccountFeeMode) || fees.mode,
					default_payment_type: PaymentType.PIX,
					// TARIFA DA CONTA:
					maintenance: {
						day: parsedFees.maintenanceDay,
						amount: parsedFees?.maintenanceAmount || 0,
						commission_percent_amount:
							parsedFees?.commissionMaintenanceAmount,
					},
					// PIX ENTRADA:
					deposit: {
						amount_type:
							(parsedFees?.depositType as AccountFeeType) ||
							AccountFeeType.FIXED,
						amount: parsedFees.deposit || 0,
					},
					// PIX SAIDA:
					withdrawal: {
						amount_type:
							(parsedFees?.pixDebitType as AccountFeeType) ||
							AccountFeeType.FIXED,
						amount: parsedFees?.pixDebit || 0,
						commission_percent_amount:
							parsedFees?.commissionWithdrawalAmount,
					},
					// TED SAIDA:
					ted_transfer: {
						amount_type:
							(parsedFees?.tedDebitType as AccountFeeType) ||
							AccountFeeType.FIXED,
						amount: parsedFees?.tedDebit || 0,
						commission_percent_amount:
							parsedFees?.commissionTedTransferAmount,
					},
					// TARIFA DE BOLETO:
					charge_settlement: {
						amount_type: AccountFeeType.FIXED,
						amount: parsedFees?.chargeSettlement || 0,
						commission_percent_amount:
							parsedFees?.commissionChargeSettlementAmount,
					},
					// COBRANÇA PIX:
					charge_pix: {
						amount_type: AccountFeeType.FIXED,
						amount: parsedFees?.chargePix || 0,
						commission_percent_amount:
							parsedFees?.commissionChargePixAmount,
					},
					//EMISSÃO DE BOLETO:
					charge_issuance: {
						amount_type: AccountFeeType.FIXED,
						amount: parsedFees?.chargeIssuance || 0,
						commission_percent_amount:
							parsedFees?.commissionChargeIssuanceAmount,
					},
				};

				createConfig({
					payload: {
						configurationPayload: props.destination
							? withDestinationPayload
							: props.fees,
						templateType: TemplateType.FEE_AND_COMMISSION_PAYMENT,
						personId: companyId || undefined,
					} as ITemplateConfigCommissionPayload,
				});
			}

			if (
				props.method === TemplateType.ESCROW_ACCOUNT_AGREEMENT ||
				selectedMethod === TemplateType.ESCROW_ACCOUNT_AGREEMENT
			) {
				if (!props.file) {
					return api.error({
						description:
							'Não localizamos o seu arquivo, faça o upload novamente',
						message: 'Houve um erro',
					});
				}

				UploadService.uploadFile(props.file)
					.then(async upload => {
						createConfig({
							payload: {
								fileKey: upload.key,
								templateType:
									TemplateType.ESCROW_ACCOUNT_AGREEMENT,
								personId: companyId || undefined,
							},
						});
					})
					.catch(async (e: ApiError) => {
						api.error({
							description: e?.data?.message,
							message: 'Ocorreu um problema ao enviar o arquivo.',
						});
					});
			}

			if (
				props.method === TemplateType.PAYMENT_ACCOUNT_AGREEMENT ||
				selectedMethod === TemplateType.PAYMENT_ACCOUNT_AGREEMENT
			) {
				if (!props.file) {
					return api.error({
						description:
							'Não localizamos o seu arquivo, faça o upload novamente',
						message: 'Houve um erro',
					});
				}

				UploadService.uploadFile(props.file)
					.then(async upload => {
						createConfig({
							payload: {
								fileKey: upload.key,
								templateType:
									TemplateType.PAYMENT_ACCOUNT_AGREEMENT,
								personId: companyId || undefined,
							},
						});
					})
					.catch(async (e: ApiError) => {
						api.error({
							description: e?.data?.message,
							message: 'Ocorreu um problema ao enviar o arquivo.',
						});
					});
			}
		},
		[companyId, selectedMethod, signatureData, signMethods],
	);

	const editConfigHandler = useCallback(
		(props: ICreateUpdateConfigProps) => {
			if (!selectedMethod && !props?.method) {
				return api.error({
					description:
						'Por favor atualize a página e tente novamente',
					message: 'Houve um erro',
				});
			}

			if (
				selectedMethod === TemplateType.SIGNATURE_METHOD_PAYMENT ||
				props.method === TemplateType.SIGNATURE_METHOD_PAYMENT
			) {
				const parseMethod = [];

				if (!signMethods.length) {
					return api.error({
						description: 'Selecione ao menos uma opção',
						message: 'Campos vazios',
					});
				}

				if (signMethods.includes('E-mail')) {
					parseMethod.push(ClicksignSignatureMethodType.EMAIL_TOKEN);
				}
				if (signMethods.includes('Selfie')) {
					parseMethod.push(ClicksignSignatureMethodType.SELFIE);
				}
				if (signMethods.includes('Certificado digital')) {
					parseMethod.push(
						ClicksignSignatureMethodType.ICP_CERTIFICATE,
					);
				}

				updateConfig({
					configId: signatureData?.id || '',
					payload: {
						configurationPayload: {
							methods: parseMethod,
						},
						templateType: TemplateType.SIGNATURE_METHOD_PAYMENT,
						personId: companyId || undefined,
					},
				});
			}

			if (
				selectedMethod === TemplateType.SIGNATURE_METHOD_ESCROW ||
				props.method === TemplateType.SIGNATURE_METHOD_ESCROW
			) {
				const parseMethod = [];

				if (!signMethods.length) {
					return api.error({
						description: 'Selecione ao menos uma opção',
						message: 'Campos vazios',
					});
				}

				if (signMethods.includes('E-mail')) {
					parseMethod.push(ClicksignSignatureMethodType.EMAIL_TOKEN);
				}
				if (signMethods.includes('Selfie')) {
					parseMethod.push(ClicksignSignatureMethodType.SELFIE);
				}
				if (signMethods.includes('Certificado digital')) {
					parseMethod.push(
						ClicksignSignatureMethodType.ICP_CERTIFICATE,
					);
				}

				updateConfig({
					configId: signatureData?.id || '',
					payload: {
						configurationPayload: {
							methods: parseMethod,
						},
						templateType: TemplateType.SIGNATURE_METHOD_ESCROW,
						personId: companyId || undefined,
					},
				});
			}

			if (
				selectedMethod === TemplateType.FEE_AND_COMMISSION_PAYMENT ||
				props.method === TemplateType.FEE_AND_COMMISSION_PAYMENT
			) {
				if (!props?.fees) {
					return api.error({
						description: 'Preencha corretamente as tarifas',
						message: 'Houve um erro',
					});
				}

				const fees: AccountFee = props.fees as AccountFee;
				const parsedFees: IAccountFeeParsed =
					props.fees as IAccountFeeParsed;
				const withDestinationPayload: ITemplateConfigAccountFee = {
					beneficiary: props.destination?.taxpayer_id
						? {
								taxpayer_id: props.destination.taxpayer_id,
								name: props.destination.name,
								bank: props.destination.bank,
								branch: props.destination.branch,
								account: props.destination.account,
								type: props.destination.type,
							}
						: undefined,
					mode: (parsedFees.feeMode as AccountFeeMode) || fees.mode,
					default_payment_type: PaymentType.PIX,
					// TARIFA DA CONTA:
					maintenance: {
						day: parsedFees.maintenanceDay,
						amount: parsedFees?.maintenanceAmount
							? parseFloat(
									parsedFees?.maintenanceAmount?.toString(),
								)
							: 0,
						commission_percent_amount:
							parsedFees?.commissionMaintenanceAmount,
					},
					// PIX ENTRADA:
					deposit: {
						amount_type:
							(parsedFees?.depositType as AccountFeeType) ||
							AccountFeeType.NONE,
						amount: parsedFees?.deposit
							? parseFloat(parsedFees.deposit.toString())
							: 0,
					},
					// PIX SAIDA:
					withdrawal: {
						amount_type:
							(parsedFees?.pixDebitType as AccountFeeType) ||
							AccountFeeType.NONE,
						amount: parsedFees?.pixDebit
							? parseFloat(parsedFees?.pixDebit.toString())
							: 0,
						commission_percent_amount:
							parsedFees?.commissionWithdrawalAmount,
					},
					// TED SAIDA:
					ted_transfer: {
						amount_type:
							(parsedFees?.tedDebitType as AccountFeeType) ||
							AccountFeeType.NONE,
						amount: parsedFees?.tedDebit
							? parseFloat(parsedFees?.tedDebit.toString())
							: 0,
						commission_percent_amount:
							parsedFees?.commissionTedTransferAmount,
					},
					// TARIFA DE BOLETO:
					charge_settlement: {
						amount_type: AccountFeeType.FIXED,
						amount: parsedFees?.chargeSettlement
							? parseFloat(
									parsedFees?.chargeSettlement.toString(),
								) || 0
							: 0,
						commission_percent_amount:
							parsedFees?.commissionChargeSettlementAmount,
					},

					// TARIFA DE COBRANÇA PIX:
					charge_pix: {
						amount_type: AccountFeeType.FIXED,
						amount: parsedFees?.chargePix
							? parseFloat(parsedFees?.chargePix.toString()) || 0
							: 0,
						commission_percent_amount:
							parsedFees?.commissionChargePixAmount,
					},
					charge_issuance: {
						amount_type: AccountFeeType.FIXED,
						amount: parsedFees?.chargeIssuance
							? parseFloat(
									parsedFees?.chargeIssuance.toString(),
								) || 0
							: 0,
						commission_percent_amount:
							parsedFees?.commissionChargeIssuanceAmount,
					},
				};

				updateConfig({
					configId: chargeDataPayment?.id || '',
					payload: {
						configurationPayload: props.destination
							? withDestinationPayload
							: props.fees,
						templateType: TemplateType.FEE_AND_COMMISSION_PAYMENT,
						personId: companyId || undefined,
					} as ITemplateConfigCommissionPayload,
				});
			}

			if (
				selectedMethod === TemplateType.FEE_AND_COMMISSION_ESCROW ||
				props.method === TemplateType.FEE_AND_COMMISSION_ESCROW
			) {
				if (!props?.fees) {
					return api.error({
						description: 'Preencha corretamente as tarifas',
						message: 'Houve um erro',
					});
				}

				const fees: AccountFee = props.fees as AccountFee;
				const parsedFees: IAccountFeeParsed =
					props.fees as IAccountFeeParsed;
				const withDestinationPayload: ITemplateConfigAccountFee = {
					beneficiary: props.destination?.taxpayer_id
						? {
								taxpayer_id: props.destination.taxpayer_id,
								name: props.destination.name,
								bank: props.destination.bank,
								branch: props.destination.branch,
								account: props.destination.account,
								type: props.destination.type,
							}
						: undefined,
					mode: (parsedFees.feeMode as AccountFeeMode) || fees.mode,
					default_payment_type: PaymentType.PIX,
					// TARIFA DA CONTA:
					maintenance: {
						day: parsedFees.maintenanceDay,
						amount: parsedFees?.maintenanceAmount
							? parseFloat(
									parsedFees?.maintenanceAmount?.toString(),
								)
							: 0,
						commission_percent_amount:
							parsedFees?.commissionMaintenanceAmount,
					},
					// PIX ENTRADA:
					deposit: {
						amount_type:
							(parsedFees?.depositType as AccountFeeType) ||
							AccountFeeType.NONE,
						amount: parsedFees?.deposit
							? parseFloat(parsedFees.deposit.toString())
							: 0,
					},
					// PIX SAIDA:
					withdrawal: {
						amount_type:
							(parsedFees?.pixDebitType as AccountFeeType) ||
							AccountFeeType.NONE,
						amount: parsedFees?.pixDebit
							? parseFloat(parsedFees?.pixDebit.toString())
							: 0,
						commission_percent_amount:
							parsedFees?.commissionWithdrawalAmount,
					},
					// TED SAIDA:
					ted_transfer: {
						amount_type:
							(parsedFees?.tedDebitType as AccountFeeType) ||
							AccountFeeType.NONE,
						amount: parsedFees?.tedDebit
							? parseFloat(parsedFees?.tedDebit.toString())
							: 0,
						commission_percent_amount:
							parsedFees?.commissionTedTransferAmount,
					},
					// TARIFA DE BOLETO:
					charge_settlement: {
						amount_type: AccountFeeType.FIXED,
						amount: parsedFees?.chargeSettlement
							? parseFloat(
									parsedFees?.chargeSettlement.toString(),
								)
							: 0,
						commission_percent_amount:
							parsedFees?.commissionChargeSettlementAmount,
					},
					// TARIFA DE COBRANÇA PIX:
					charge_pix: {
						amount_type: AccountFeeType.FIXED,
						amount: parsedFees?.chargePix
							? parseFloat(parsedFees?.chargePix.toString())
							: 0,
						commission_percent_amount:
							parsedFees?.commissionChargePixAmount,
					},
					charge_issuance: {
						amount_type: AccountFeeType.FIXED,
						amount: parsedFees?.chargeIssuance
							? parseFloat(parsedFees?.chargeIssuance.toString())
							: 0,
						commission_percent_amount:
							parsedFees?.commissionChargeIssuanceAmount,
					},
				};

				updateConfig({
					configId: chargeData?.id || '',
					payload: {
						configurationPayload: props.destination
							? withDestinationPayload
							: props.fees,
						templateType: TemplateType.FEE_AND_COMMISSION_ESCROW,
						personId: companyId || undefined,
					} as ITemplateConfigCommissionPayload,
				});
			}
		},
		[signMethods, selectedMethod, signatureData, chargeData, companyId],
	);

	const deleteConfigHandler = useCallback(
		(method?: TemplateType) => {
			if (
				selectedMethod === TemplateType.SIGNATURE_METHOD_ESCROW &&
				signatureData?.id
			) {
				return deleteConfig({
					configId: signatureData?.id || '',
				});
			}

			if (
				selectedMethod === TemplateType.SIGNATURE_METHOD_PAYMENT &&
				signatureDataPayment?.id
			) {
				return deleteConfig({
					configId: signatureDataPayment?.id || '',
				});
			}

			if (
				selectedMethod === TemplateType.FEE_AND_COMMISSION_ESCROW &&
				chargeData?.id
			) {
				return deleteConfig({
					configId: chargeData?.id || '',
				});
			}

			if (
				selectedMethod === TemplateType.FEE_AND_COMMISSION_PAYMENT &&
				chargeDataPayment?.id
			) {
				return deleteConfig({
					configId: chargeDataPayment?.id || '',
				});
			}

			if (selectedMethod === TemplateType.ESCROW_ACCOUNT_AGREEMENT) {
				return deleteConfig({
					configId: escrowContractData?.id || '',
				});
			}

			if (selectedMethod === TemplateType.PAYMENT_ACCOUNT_AGREEMENT) {
				return deleteConfig({
					configId: paymentContractData?.id || '',
				});
			}

			if (method === TemplateType.SKIP_AGREEMENT_ESCROW) {
				return deleteConfig({
					configId: skipContractSignaturesEscrow?.id || '',
				});
			}

			if (method === TemplateType.SKIP_AGREEMENT_PAYMENT) {
				return deleteConfig({
					configId: skipContractSignaturesPayment?.id || '',
				});
			}
		},
		[
			signatureData,
			selectedMethod,
			chargeDataPayment,
			signatureDataPayment,
			paymentContractData,
			escrowContractData,
			chargeData,
			skipContractSignaturesPayment,
			skipContractSignaturesEscrow,
		],
	);

	const handleSetSignMethods = (methods: string[]) => {
		if (methods.length > 1 && methods.includes('Certificado digital')) {
			api.error({
				description:
					'Certificado digital não pode ser selecionado com outros métodos',
				message: 'Erro',
			});
			return;
		}

		setSignMethods(methods);
	};

	const downloadDocumentHandler = (fileUrl: string) => {
		if (!fileUrl) {
			return api.error({
				description:
					'O arquivo não está disponível para download no momento, tente novamente mais tarde.',
				message: 'Arquivo não disponível',
			});
		}

		saveAs(fileUrl);
	};

	return (
		<>
			{contextHolder}
			<Heading1>Configurações de abertura de contas</Heading1>
			<TextM color={theme.textGray} style={{ marginTop: '-14px' }}>
				As configurações alteradas serão refletidas nas próximas
				aberturas de contas.
			</TextM>
			<Divider />

			<Collapse
				items={[
					{
						key: '1',
						label: (
							<CollapseHeader>
								<TextM>Conta Escrow</TextM>
							</CollapseHeader>
						),
						children: (
							<>
								{/* Tarifas Escrow */}
								{!!chargeData?.configuration_payload ? (
									<FeeDescriptions
										title="Tarifas e rebates"
										fees={chargeData.configuration_payload}
										handleOnEdit={() => {}}
										onEditComponent={
											<ActionsButtonsComponent
												editTitleButton="Editar tarifas"
												onClickEdit={() => {
													setSelectedMethod(
														TemplateType.FEE_AND_COMMISSION_ESCROW,
													);
													setIsEditing(true);
													setIsOpenChargeConfigModal(
														true,
													);
												}}
												onClickRemove={() => {
													setSelectedMethod(
														TemplateType.FEE_AND_COMMISSION_ESCROW,
													);
													setIsOpenDeleteModal(true);
												}}
											/>
										}
									/>
								) : (
									<EmptyConfigComponent
										subtitle="Esta conta não possui nenhuma configuração de tarifas para abertura de novas contas."
										title="Tarifas e rebates"
										button={
											<Button
												type="primary"
												onClick={() =>
													setIsOpenChargeConfigModal(
														true,
													)
												}
												style={{ width: '13rem' }}
											>
												<TextS color={theme.white}>
													Configurar tarifas
												</TextS>
												<AddCircleIcon
													color={theme.white}
													size={18}
												/>
											</Button>
										}
									/>
								)}
								{/* Skip de contrato  */}
								{!!companyId && (
									<>
										<Divider />

										<Flex
											align="center"
											justify="space-between"
										>
											<Heading2>
												Fluxo de contrato
											</Heading2>
											<div
												style={{
													padding: '0px 16px',
												}}
											>
												<Switch
													size="default"
													checked={
														!skipContractSignaturesEscrow?.configuration_payload
													}
													onChange={event => {
														setSelectedMethod(
															TemplateType.SKIP_AGREEMENT_ESCROW,
														);

														if (event) {
															return deleteConfigHandler(
																TemplateType.SKIP_AGREEMENT_ESCROW,
															);
														}

														return createConfigHandler(
															{
																method: TemplateType.SKIP_AGREEMENT_ESCROW,
															},
														);
													}}
												/>
											</div>
										</Flex>
										<Flex>
											<TextS
												color={theme.textGray}
												style={{ marginTop: '0.5rem' }}
											>
												{!skipContractSignaturesEscrow?.configuration_payload
													? 'Ativado: O fluxo inclui a etapa de contrato.'
													: 'Desativado: O fluxo pula a etapa de contrato.'}
											</TextS>
										</Flex>
									</>
								)}
								<Divider />
								{/* Contrato Escrow */}
								{!!escrowContractData ? (
									<div>
										<Flex
											align="center"
											justify="space-between"
										>
											<Heading2>
												Template de contrato
											</Heading2>

											<ActionsButtonsComponent
												editTitleButton="Enviar outro arquivo"
												onClickRemove={() => {
													setSelectedMethod(
														TemplateType.ESCROW_ACCOUNT_AGREEMENT,
													);
													setIsOpenDeleteModal(true);
												}}
											/>
										</Flex>
										{isLoadingFile ? (
											<div
												style={{
													marginTop: '1rem',
													marginBottom: '-1rem',
												}}
											>
												<Skeleton
													active
													paragraph={{
														rows: 0,
														width: 160,
													}}
												/>
											</div>
										) : (
											<Button
												type="text"
												style={{ padding: '1rem 0' }}
												onClick={() =>
													downloadDocumentHandler(
														escrowContractData?.file_url,
													)
												}
											>
												<Flex
													align="center"
													justify="flex-start"
												>
													<FileCheckLine
														color={theme.primary}
														size={18}
													/>
													<TextS
														color={theme.textGray}
														style={{
															marginLeft:
																'0.5rem',
														}}
													>
														{escrowContractData?.file_name ||
															'Template Conta Escrow'}
													</TextS>
												</Flex>
											</Button>
										)}
									</div>
								) : (
									<EmptyConfigComponent
										subtitle="Esta conta não possui nenhum template de contrato para abertura de novas contas."
										title="Template de contrato"
										button={
											<Upload {...props} accept=".docx">
												<Button
													type="primary"
													style={{ width: '13rem' }}
												>
													<TextS color={theme.white}>
														Adicionar template
													</TextS>
													<AddCircleIcon
														color={theme.white}
														size={18}
													/>
												</Button>
											</Upload>
										}
									/>
								)}
								{/* Assinatura Escrow */}
								<Divider />
								{!!signatureData ? (
									<>
										<Flex
											align="center"
											justify="space-between"
										>
											<Heading2>
												Método de assinatura
											</Heading2>
											<ActionsButtonsComponent
												editTitleButton="Editar método"
												onClickEdit={() => {
													setSelectedMethod(
														TemplateType.SIGNATURE_METHOD_ESCROW,
													);
													setIsEditing(true);
													setIsOpenSignerMethodEscrow(
														true,
													);
												}}
												onClickRemove={() => {
													setSelectedMethod(
														TemplateType.SIGNATURE_METHOD_ESCROW,
													);
													setIsOpenDeleteModal(true);
												}}
											/>
										</Flex>
										<Flex
											align="center"
											style={{ marginTop: '2rem' }}
										>
											<TextS color={theme.textGray}>
												Método de assinatura:
											</TextS>
											<StyledMethods>
												{methods?.map(
													getMethodDisplayName,
												)}
											</StyledMethods>
										</Flex>
									</>
								) : (
									<EmptyConfigComponent
										subtitle="Esta conta não possui nenhum método de assinatura definido para abertura de novas contas."
										title="Método de assinatura"
										button={
											<Button
												type="primary"
												onClick={() =>
													setIsOpenSignerMethodEscrow(
														true,
													)
												}
												style={{ width: '13rem' }}
											>
												<TextS color={theme.white}>
													Adicionar método
												</TextS>
												<AddCircleIcon
													color={theme.white}
													size={18}
												/>
											</Button>
										}
									/>
								)}
							</>
						),
					},
				]}
			/>

			<Collapse
				style={{ marginTop: '2rem' }}
				items={[
					{
						key: '2',
						label: (
							<CollapseHeader>
								<TextM>Conta Livre</TextM>
							</CollapseHeader>
						),
						children: (
							<>
								{/* Tarifas Livre */}
								{!!chargeDataPayment?.configuration_payload ? (
									<FeeDescriptions
										title="Tarifas e rebates"
										fees={
											chargeDataPayment.configuration_payload
										}
										handleOnEdit={() => {}}
										onEditComponent={
											<ActionsButtonsComponent
												editTitleButton="Editar tarifas"
												onClickEdit={() => {
													setSelectedMethod(
														TemplateType.FEE_AND_COMMISSION_PAYMENT,
													);
													setIsEditing(true);
													setIsOpenChargeConfigModalPayment(
														true,
													);
												}}
												onClickRemove={() => {
													setSelectedMethod(
														TemplateType.FEE_AND_COMMISSION_PAYMENT,
													);
													setIsOpenDeleteModal(true);
												}}
											/>
										}
									/>
								) : (
									<EmptyConfigComponent
										subtitle="Esta conta não possui nenhuma configuração de tarifas para abertura de novas contas."
										title="Tarifas e rebates"
										button={
											<Button
												type="primary"
												onClick={() =>
													setIsOpenChargeConfigModalPayment(
														true,
													)
												}
												style={{ width: '13rem' }}
											>
												<TextS color={theme.white}>
													Configurar tarifas
												</TextS>
												<AddCircleIcon
													color={theme.white}
													size={18}
												/>
											</Button>
										}
									/>
								)}
								{/* Skip de contrato  */}
								{!!companyId && (
									<>
										<Divider />

										<Flex
											align="center"
											justify="space-between"
										>
											<Heading2>
												Fluxo de contrato
											</Heading2>
											<div
												style={{
													padding: '0px 16px',
												}}
											>
												<Switch
													size="default"
													checked={
														!skipContractSignaturesPayment?.configuration_payload
													}
													onChange={event => {
														setSelectedMethod(
															TemplateType.SKIP_AGREEMENT_PAYMENT,
														);

														if (event) {
															return deleteConfigHandler(
																TemplateType.SKIP_AGREEMENT_PAYMENT,
															);
														}

														return createConfigHandler(
															{
																method: TemplateType.SKIP_AGREEMENT_PAYMENT,
															},
														);
													}}
												/>
											</div>
										</Flex>
										<Flex>
											<TextS
												color={theme.textGray}
												style={{ marginTop: '0.5rem' }}
											>
												{!skipContractSignaturesPayment?.configuration_payload
													? 'Ativado: O fluxo inclui a etapa de contrato.'
													: 'Desativado: O fluxo pula a etapa de contrato.'}
											</TextS>
										</Flex>
									</>
								)}
								<Divider />
								{/* Contrato Livre */}
								{!!paymentContractData ? (
									<div>
										<Flex
											align="center"
											justify="space-between"
										>
											<Heading2>
												Template de contrato
											</Heading2>

											<ActionsButtonsComponent
												editTitleButton="Enviar outro arquivo"
												onClickRemove={() => {
													setSelectedMethod(
														TemplateType.PAYMENT_ACCOUNT_AGREEMENT,
													);
													setIsOpenDeleteModal(true);
												}}
											/>
										</Flex>
										{isLoadingPaymentFile ? (
											<div
												style={{
													marginTop: '1rem',
													marginBottom: '-1rem',
												}}
											>
												<Skeleton
													active
													paragraph={{
														rows: 0,
														width: 160,
													}}
												/>
											</div>
										) : (
											<Button
												type="text"
												style={{ padding: '1rem 0' }}
												onClick={() =>
													downloadDocumentHandler(
														paymentContractData?.file_url,
													)
												}
											>
												<Flex
													align="center"
													justify="flex-start"
												>
													<FileCheckLine
														color={theme.primary}
														size={18}
													/>
													<TextS
														color={theme.textGray}
														style={{
															marginLeft:
																'0.5rem',
														}}
													>
														{paymentContractData?.file_name ||
															'Template Conta Livre'}
													</TextS>
												</Flex>
											</Button>
										)}
									</div>
								) : (
									<EmptyConfigComponent
										subtitle="Esta conta não possui nenhum template de contrato para abertura de novas contas."
										title="Template de contrato"
										button={
											<Upload
												{...propsPayment}
												accept=".docx"
											>
												<Button
													type="primary"
													style={{ width: '13rem' }}
												>
													<TextS color={theme.white}>
														Adicionar template
													</TextS>
													<AddCircleIcon
														color={theme.white}
														size={18}
													/>
												</Button>
											</Upload>
										}
									/>
								)}

								{/* Assinatura Livre */}
								<Divider />
								{!!signatureDataPayment ? (
									<>
										<Flex
											align="center"
											justify="space-between"
										>
											<Heading2>
												Método de assinatura
											</Heading2>
											<ActionsButtonsComponent
												editTitleButton="Editar método"
												onClickEdit={() => {
													setSelectedMethod(
														TemplateType.SIGNATURE_METHOD_PAYMENT,
													);
													setIsEditing(true);
													setIsOpenSignerMethodPayment(
														true,
													);
												}}
												onClickRemove={() => {
													setSelectedMethod(
														TemplateType.SIGNATURE_METHOD_PAYMENT,
													);
													setIsOpenDeleteModal(true);
												}}
											/>
										</Flex>
										<Flex
											align="center"
											style={{ marginTop: '2rem' }}
										>
											<TextS color={theme.textGray}>
												Método de assinatura:
											</TextS>
											<StyledMethods>
												{methodsPayment?.map(
													getMethodDisplayName,
												)}
											</StyledMethods>
										</Flex>
									</>
								) : (
									<EmptyConfigComponent
										subtitle="Esta conta não possui nenhum método de assinatura definido para abertura de novas contas."
										title="Método de assinatura"
										button={
											<Button
												type="primary"
												onClick={() =>
													setIsOpenSignerMethodPayment(
														true,
													)
												}
												style={{ width: '13rem' }}
											>
												<TextS color={theme.white}>
													Adicionar método
												</TextS>
												<AddCircleIcon
													color={theme.white}
													size={18}
												/>
											</Button>
										}
									/>
								)}
							</>
						),
					},
				]}
			/>

			{/* Modals */}
			<FeeSettingsModalComission
				accountId={companyId}
				isOpen={isOpenChargeConfigModal}
				onClose={() => setIsOpenChargeConfigModal(false)}
				feesSettings={{
					...chargeData?.configuration_payload,
					default_payment_type: PaymentType.PIX,
					mode:
						chargeData?.configuration_payload.mode ||
						AccountFeeMode.DIRECT_DEBIT,
					commission_account_destination: {
						account:
							chargeData?.configuration_payload.beneficiary
								?.account || '',
						bank:
							chargeData?.configuration_payload.beneficiary
								?.bank || '',
						branch:
							chargeData?.configuration_payload.beneficiary
								?.branch || '',
						name:
							chargeData?.configuration_payload.beneficiary
								?.name || '',
						taxpayer_id:
							chargeData?.configuration_payload.beneficiary
								?.taxpayer_id || '',
						type:
							chargeData?.configuration_payload.beneficiary
								?.type || '',
					},
				}}
				isEdit
				isTemplateConfig
				handleConfirm={(fees, destination) => {
					isEditing
						? editConfigHandler({
								method: TemplateType.FEE_AND_COMMISSION_ESCROW,
								fees: fees,
								destination: destination,
							})
						: createConfigHandler({
								method: TemplateType.FEE_AND_COMMISSION_ESCROW,
								fees: fees,
								destination: destination,
							});
				}}
			/>
			<FeeSettingsModalComission
				accountId={companyId}
				isOpen={isOpenChargeConfigModalPayment}
				onClose={() => setIsOpenChargeConfigModalPayment(false)}
				feesSettings={{
					...chargeDataPayment?.configuration_payload,
					default_payment_type: PaymentType.PIX,
					mode:
						chargeDataPayment?.configuration_payload.mode ||
						AccountFeeMode.DIRECT_DEBIT,
					commission_account_destination: {
						account:
							chargeDataPayment?.configuration_payload.beneficiary
								?.account || '',
						bank:
							chargeDataPayment?.configuration_payload.beneficiary
								?.bank || '',
						branch:
							chargeDataPayment?.configuration_payload.beneficiary
								?.branch || '',
						name:
							chargeDataPayment?.configuration_payload.beneficiary
								?.name || '',
						taxpayer_id:
							chargeDataPayment?.configuration_payload.beneficiary
								?.taxpayer_id || '',
						type:
							chargeDataPayment?.configuration_payload.beneficiary
								?.type || '',
					},
				}}
				isEdit
				isTemplateConfig
				handleConfirm={(fees, destination) => {
					isEditing
						? editConfigHandler({
								method: TemplateType.FEE_AND_COMMISSION_PAYMENT,
								fees: fees,
								destination: destination,
							})
						: createConfigHandler({
								method: TemplateType.FEE_AND_COMMISSION_PAYMENT,
								fees: fees,
								destination: destination,
							});
				}}
			/>
			<SignerMethodModal
				type={AccountType.ESCROW}
				isOpen={isOpenSignerMethodEscrow}
				onClose={() => setIsOpenSignerMethodEscrow(false)}
				options={
					signMethods || signatureData?.configuration_payload.methods
				}
				setOptions={options => handleSetSignMethods(options)}
				onSave={() =>
					isEditing
						? editConfigHandler({
								method: TemplateType.SIGNATURE_METHOD_ESCROW,
							})
						: createConfigHandler({
								method: TemplateType.SIGNATURE_METHOD_ESCROW,
							})
				}
			/>
			<SignerMethodModal
				type={AccountType.PAYMENT}
				isOpen={isOpenSignerMethodPayment}
				onClose={() => setIsOpenSignerMethodPayment(false)}
				setOptions={options => handleSetSignMethods(options)}
				options={
					signMethods || signatureData?.configuration_payload.methods
				}
				onSave={() =>
					isEditing
						? editConfigHandler({
								method: TemplateType.SIGNATURE_METHOD_PAYMENT,
							})
						: createConfigHandler({
								method: TemplateType.SIGNATURE_METHOD_PAYMENT,
							})
				}
			/>
			<ConfirmationModal
				isOpen={isOpenDeleteModal}
				danger={true}
				isLoading={isLoading}
				title="Remover configuração"
				confirmText="Remover configuração"
				cancelText="Cancelar"
				onConfirm={deleteConfigHandler}
				onCancel={() => setIsOpenDeleteModal(false)}
				onClose={() => setIsOpenDeleteModal(false)}
			>
				<TextM>
					Você tem certeza que deseja remover essa configuração?
				</TextM>
			</ConfirmationModal>
		</>
	);
};

export default TemplateConfigContainer;
