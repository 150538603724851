import { useQuery } from '@tanstack/react-query';
import { Modal } from 'components/Modal';
import { AccountsService } from 'modules/escrow/services';
import { useParams } from 'react-router-dom';
import {
	DiscountPix,
	EPix,
	PixChargeDiscountType,
	PixChargeFineType,
	pixDisplay,
} from '../types';
import {
	StyledButtons,
	StyledChipWrapper,
	StyledFooter,
	StyledPixDetails,
	StyledQrCode,
} from './PixDetailsModal.styles';
import ChipComponent from 'components/Chip/Chip';
import theme from 'theme/theme';
import { TextM, TextS, TextSS } from 'components/Text';
import { Button, Col, Collapse, Divider, Flex, Row, notification } from 'antd';
import { CollapseExpander } from 'components/Collapse/styles';
import { ExpanderIcon } from 'components/Icons/Expander';
import { ReactNode } from 'react';
import { formatCurrency, normalizeCpfCnpj } from 'helpers/normalizers';
import { RiCoinsLine, RiFileCopyLine } from 'react-icons/ri';
import dayjs from 'dayjs';
import { TbCalendarEvent, TbReceipt2 } from 'react-icons/tb';
import { PersonIcon } from 'components/Icons/Person';
import { MdAlternateEmail } from 'react-icons/md';
import { FiMapPin } from 'react-icons/fi';
import { ArrowRightIcon } from 'components/Icons/ArrowRight';

interface IPixDetailsModalProps {
	closeModal: () => void;
	redirectReceipt: () => void;
	pixId: string;
}

const PixDetailsModal = ({
	closeModal,
	redirectReceipt,
	pixId,
}: IPixDetailsModalProps) => {
	const [api, contextHolder] = notification.useNotification();
	const { id: entityId } = useParams<{ id: string }>();

	const { data: pix, isLoading } = useQuery({
		queryKey: ['getPixCharge', pixId],
		queryFn: () => AccountsService.getPixCharge(entityId!, pixId!),
	});

	const address = pix?.debtor?.address;

	const settings: DiscountPix = !!pix?.discount_dates_settings
		? pix?.discount_dates_settings[0]
		: ({} as DiscountPix);

	const renderInfo = (title: string, subtitle: string, icon: ReactNode) => {
		return (
			<Row style={{ width: '100%', marginBottom: '0.6rem' }}>
				<Col span={2} style={{ alignSelf: 'center' }}>
					{icon}
				</Col>
				<Col span={22}>
					<small style={{ color: theme.textGray }}>{title}</small>
					<TextS weight="bold" color={theme.textSecondary}>
						{subtitle}
					</TextS>
				</Col>
			</Row>
		);
	};

	const handleCopy = () => {
		if (!pix?.emv) {
			return api.info({
				message: 'Erro ao copiar',
				description: 'Atualize a página e tente novamente.',
			});
		}

		navigator.clipboard.writeText(pix?.emv);

		api.info({
			message: 'Código pix copiado',
		});
	};

	/* Collapse items */
	const items = [
		{
			key: '1',
			label: <TextM color={theme.textSecondary}>Dados da operação</TextM>,
			children: (
				<div>
					{renderInfo(
						'Valor da cobrança',
						formatCurrency(parseFloat(pix?.amount || '0')) || '-',
						<RiCoinsLine color={theme.primary} size={18} />,
					)}
					{renderInfo(
						'Data da transferência',
						dayjs(pix?.due_date).format('DD/MM/YYYY') || '-',
						<TbCalendarEvent color={theme.primary} size={18} />,
					)}
					{renderInfo(
						'Data limite de pagamento',
						dayjs(pix?.due_date)
							.add(pix?.expiration_after_payment || 0, 'day')
							.format('DD/MM/YYYY') || '-',
						<TbCalendarEvent color={theme.primary} size={18} />,
					)}
					{renderInfo(
						'Juros',
						`${pix?.interest_amount || 0} %`,
						<TbReceipt2 color={theme.primary} size={18} />,
					)}
					{renderInfo(
						'Multa',
						pix?.fine_type === PixChargeFineType.PERCENT
							? `${pix?.fine_amount || 0} %`
							: formatCurrency(pix?.fine_amount) || '0 %',
						<TbReceipt2 color={theme.primary} size={18} />,
					)}
					{renderInfo(
						'Desconto',
						pix?.discount_type ===
							PixChargeDiscountType.PERCENTAGE_DATE_REPORTED
							? `${settings?.amount || 0} %`
							: formatCurrency(settings?.amount) || '0 %',
						<TbReceipt2 color={theme.primary} size={18} />,
					)}
					{renderInfo(
						'Desconto aplicado se for pago até',
						!!settings.amount
							? dayjs(settings?.date).format('DD/MM/YYYY')
							: '-',
						<TbCalendarEvent color={theme.primary} size={18} />,
					)}
				</div>
			),
		},
		{
			key: '2',
			label: <TextM color={theme.textSecondary}>Dados do devedor</TextM>,
			children: (
				<div>
					{renderInfo(
						'CPF / CNPJ',
						normalizeCpfCnpj(pix?.debtor.taxpayer_id),
						<PersonIcon color={theme.primary} size={18} />,
					)}
					{renderInfo(
						'E-mail para contato',
						`${pix?.debtor.email_address || 'Não informado.'}`,
						<MdAlternateEmail color={theme.primary} size={18} />,
					)}
					{renderInfo(
						'Endereço',
						address?.street_name
							? `${address?.postal_code || ''} - ${address?.street_name || ''},
						${address?.street_number || 'S/N'} - ${address?.extra_info || ''} ${address?.district || ''}
						- ${address?.city || ''}, ${address?.state_code || ''}`
							: 'Endereço não informado',
						<FiMapPin color={theme.primary} size={18} />,
					)}
				</div>
			),
		},
	];

	return (
		<>
			<Modal isOpen title="Detalhes da cobrança" onClose={closeModal}>
				{contextHolder}
				<StyledPixDetails>
					{/* Header */}
					<StyledChipWrapper>
						<ChipComponent
							alignText="center"
							statusColor={
								pix?.status
									? pixDisplay[pix?.status]?.textColor
									: theme.textGray
							}
							backgroundColor={
								pix?.status
									? pixDisplay[pix?.status]?.background
									: theme.grayLight
							}
						>
							<TextSS
								weight="bold"
								color={
									pix?.status
										? pixDisplay[pix?.status]?.textColor
										: theme.textGray
								}
								style={{
									maxWidth: '8rem',
								}}
							>
								{pix?.status
									? pixDisplay[pix?.status]?.label
									: 'Status indisponível'}
							</TextSS>
						</ChipComponent>
					</StyledChipWrapper>

					{pix?.status !== EPix.EXPIRED && (
						<StyledQrCode>
							{pix?.pre_signed_url && (
								<img
									src={pix.pre_signed_url}
									alt="QR Code"
									style={{
										maxWidth: '13rem',
										maxHeight: '13rem',
									}}
								/>
							)}
						</StyledQrCode>
					)}

					<StyledButtons>
						{/* Fora do escopo no momento  */}
						{/* {pix?.url && (
							<Button
								style={{ backgroundColor: 'transparent' }}
								onClick={}
							>
								<TextS color={theme.primary}>
									<AiOutlineLink size={18} />
									Link de pagamento
								</TextS>
							</Button>
						)} */}
						{pix?.pix_key && (
							<Button
								style={{
									backgroundColor: 'transparent',
								}}
								onClick={handleCopy}
								disabled={pix.status === EPix.EXPIRED}
							>
								<TextS color={theme.primary}>
									<RiFileCopyLine size={18} />
									Copiar código
								</TextS>
							</Button>
						)}
					</StyledButtons>

					{/* Content */}
					<Collapse
						items={items}
						bordered={false}
						defaultActiveKey={['1']}
						expandIconPosition="end"
						style={{
							backgroundColor: 'transparent',
							width: '100%',
						}}
						expandIcon={({ isActive }) => (
							<CollapseExpander isActive={isActive}>
								<ExpanderIcon />
							</CollapseExpander>
						)}
					/>
					<Divider style={{ margin: 0, background: theme.border }} />
					{pix?.status === EPix.PAID && (
						<Row
							style={{
								width: '100%',
								margin: '1rem',
								paddingLeft: '1rem',
							}}
						>
							<Col span={2} style={{ alignSelf: 'center' }}>
								<TbReceipt2 color={theme.primary} size={18} />
							</Col>
							<Col span={22}>
								<Flex align="center" justify="space-between">
									<TextS
										weight="bold"
										color={theme.textSecondary}
									>
										Comprovante
									</TextS>
									<Button
										type="text"
										onClick={redirectReceipt}
										disabled={isLoading}
									>
										<TextSS color={theme.primary}>
											Ver pagamento
										</TextSS>
										<ArrowRightIcon size={18} />
									</Button>
								</Flex>
							</Col>
						</Row>
					)}
				</StyledPixDetails>

				{/* Footer */}
				<StyledFooter>
					<Button type="text" onClick={closeModal}>
						<TextM color={theme.primary}>Voltar</TextM>
					</Button>
				</StyledFooter>
			</Modal>
		</>
	);
};

export default PixDetailsModal;
