import styled from 'styled-components';

export const StyledFooter = styled.div`
	margin-top: 1rem;
	display: flex;
	justify-content: flex-end;
`;

export const StyledContent = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 1rem;
	border-radius: 5px;
	height: 40rem;
	overflow-y: auto;
	overflow-x: hidden;
	align-items: center;
	width: 100%;
`;
