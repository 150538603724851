import {
	Col,
	DatePicker,
	Flex,
	Form,
	Input,
	InputNumber,
	Row,
	Switch,
	notification,
} from 'antd';
import { TextL, TextS } from 'components/Text';
import theme from 'theme/theme';
import { ICreatePixForm } from '../../types';
import { useState } from 'react';
import { minValue } from 'helpers/rules';
import { CurrencyInput } from 'components/CurrencyInput';
import { InputTypeSelection } from 'components/InputTypeSelection';
import dayjs, { Dayjs } from 'dayjs';
import { localeDatePicker } from 'helpers/dates';

interface IOperationFormProps {
	pixCharge: ICreatePixForm;
	setPixCharge: (values: ICreatePixForm) => void;
}

const OperationForm = ({ pixCharge, setPixCharge }: IOperationFormProps) => {
	/* Hooks */
	const [form] = Form.useForm();
	const [api, contextHolder] = notification.useNotification();

	/* State */
	const [hasDiscount, setHasDiscount] = useState<boolean>(false);
	const [minimumDate, setMinimumDate] = useState<Dayjs>(
		dayjs().startOf('day'),
	);

	return (
		<>
			{contextHolder}
			<TextL
				style={{ alignSelf: 'flex-start', marginBottom: '1rem' }}
				color={theme.textSecondary}
			>
				Dados da operação
			</TextL>

			<Form<ICreatePixForm>
				form={form}
				layout="vertical"
				initialValues={pixCharge}
				onFieldsChange={f => {
					setPixCharge({ ...pixCharge, [f[0].name]: f[0].value });
				}}
				style={{ width: '100%' }}
			>
				<Row>
					<Col span={24}>
						<Form.Item
							name="amount"
							label="Valor"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								minValue(
									'amount',
									0.01,
									'Valor deve ser maior que R$0,00',
								),
							]}
						>
							<CurrencyInput placeholder="0,00" />
						</Form.Item>
					</Col>
					<Col span={24} style={{ margin: '10px 0' }}>
						<Form.Item
							name="dueDate"
							label="Data de vencimento"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<DatePicker
								style={{
									width: '100%',
								}}
								placeholder="dd/mm/aaaa"
								format="DD/MM/YYYY"
								minDate={dayjs().startOf('day')}
								locale={localeDatePicker}
								onChange={date =>
									setMinimumDate(
										date?.add(1, 'day') ||
											dayjs().startOf('day'),
									)
								}
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item name="description" label="Descrição">
							<Input placeholder="Digite aqui" />
						</Form.Item>
					</Col>
					<Col span={24} style={{ margin: '10px 0' }}>
						<Form.Item
							name="expirationAfterPayment"
							label="Data limite de pagamento"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<DatePicker
								style={{
									width: '100%',
								}}
								placeholder="dd/mm/aaaa"
								format="DD/MM/YYYY"
								minDate={minimumDate}
								locale={localeDatePicker}
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item name="interestAmount" label="Juros (a.m.)">
							<InputNumber
								min={0}
								max={100}
								style={{ width: '100%' }}
								prefix={'%'}
								changeOnWheel={false}
								decimalSeparator=","
								placeholder="0"
							/>
						</Form.Item>
					</Col>
					<Col span={24} style={{ marginTop: '10px' }}>
						<InputTypeSelection
							amount={pixCharge?.fineAmount}
							inputSelectType={pixCharge?.fineType}
							formItemName="fineAmount"
							title="Multa"
							setSelectType={value => {
								setPixCharge({
									...pixCharge,
									fineType: value,
								});
							}}
						/>
					</Col>
				</Row>

				<Flex
					align="center"
					justify="space-between"
					style={{ margin: '1rem 0' }}
				>
					<TextS color={theme.textSecondary}>Possui desconto?</TextS>
					<Switch
						checked={hasDiscount}
						onChange={() => {
							setPixCharge({
								...pixCharge,
								discountAmount: 0,
								discountDate: undefined,
							});
							setHasDiscount(!hasDiscount);
						}}
					/>
				</Flex>

				{hasDiscount && (
					<Row style={{ marginBottom: '3rem' }}>
						<Col span={24}>
							<InputTypeSelection
								amount={pixCharge?.discountAmount}
								inputSelectType={pixCharge?.discountType}
								formItemName="discountAmount"
								title="Valor do desconto"
								setSelectType={value => {
									setPixCharge({
										...pixCharge,
										discountType: value,
									});
								}}
							/>
						</Col>
						<Col span={24} style={{ margin: '10px 0' }}>
							<Form.Item
								name="discountDate"
								label="Desconto aplicado se for pago até"
							>
								<DatePicker
									style={{
										width: '100%',
									}}
									placeholder="dd/mm/aaaa"
									format="DD/MM/YYYY"
									minDate={dayjs().startOf('day')}
									maxDate={minimumDate}
									locale={localeDatePicker}
								/>
							</Form.Item>
						</Col>
					</Row>
				)}
			</Form>
		</>
	);
};

export default OperationForm;
