import { DescriptionsVertical } from 'components/DescriptionsVertical';
import { formatCurrency } from 'helpers/normalizers';
import { getFormatterFee } from 'modules/escrow/utils/stringFormatter';
import React from 'react';
import { AccountFee } from 'types/Account';

export interface FeeDescriptionsProps {
	title: string;
	fees?: AccountFee | null;
	onEditComponent?: React.ReactNode;
	extraContent?: React.ReactNode;
	handleOnEdit?: () => void;
}

const FeeDescriptions = ({
	title,
	fees,
	onEditComponent,
	extraContent,
	handleOnEdit,
}: FeeDescriptionsProps) => {
	const feeItems = [
		{
			key: '1',
			label: 'Tarifa de manutenção',
			children: fees?.maintenance?.amount
				? formatCurrency(
						parseFloat(fees?.maintenance?.amount.toString()),
					)
				: '-',
		},
		{
			key: '2',
			label: 'Tarifa Pix',
			children: fees?.withdrawal?.amount
				? getFormatterFee(
						parseFloat(fees?.withdrawal?.amount?.toString()),
						fees?.withdrawal?.amount_type,
					)
				: '-',
		},
		{
			key: '3',
			label: 'Tarifa de entrada',
			children: getFormatterFee(
				fees?.deposit?.amount,
				fees?.deposit?.amount_type,
			),
		},
		{
			key: '4',
			label: 'Tarifa de liquidação de boleto',
			children: getFormatterFee(
				fees?.charge_settlement?.amount,
				fees?.charge_settlement?.amount_type,
			),
		},
		{
			key: '5',
			label: 'Tarifa de cobrança Pix',
			children: getFormatterFee(
				fees?.charge_pix?.amount,
				fees?.charge_pix?.amount_type,
			),
		},
		{
			key: '6',
			label: 'Tarifa de emissão de boleto',
			children: getFormatterFee(
				fees?.charge_issuance?.amount,
				fees?.charge_issuance?.amount_type,
			),
		},
		{
			key: '7',
			label: 'Data de cobrança da tarifa',
			children: fees?.maintenance?.day
				? `Todo dia ${fees.maintenance.day}`
				: '-',
		},
		{
			key: '8',
			label: 'Tarifa TED',
			children: getFormatterFee(
				fees?.ted_transfer?.amount,
				fees?.ted_transfer?.amount_type,
			),
		},
		{
			key: '9',
			label: 'Método de pagamento padrão',
			children: fees?.default_payment_type
				? fees.default_payment_type
				: '-',
		},
	];

	return (
		<DescriptionsVertical
			title={title ?? 'Tarifas'}
			columns={2}
			items={feeItems}
			onEdit={handleOnEdit}
			onEditComponent={onEditComponent}
			extraContent={extraContent}
		/>
	);
};

export default FeeDescriptions;
