import { Form } from 'antd';
import styled from 'styled-components';

export const DescriptionsContainer = styled.div`
	.ant-descriptions-view {
		border: none !important;
		padding: 0px 8px !important;
	}

	.ant-descriptions-item-label {
		background-color: transparent !important;
		border-inline-end: none !important;
		padding: 3px 16px !important;
		font-weight: bold;
		font-size: 12px;
		color: ${p => p.theme.textSecondary} !important;
	}

	.ant-descriptions-item-content {
		padding: 3px 16px !important;
		text-transform: uppercase;
		font-size: 12px;
		color: ${p => p.theme.textGray} !important;
	}

	.ant-descriptions-row {
		border-bottom: none !important;
	}
`;

export const ReceiptFooter = styled.div`
	height: 70px;
	color: ${p => p.theme.textGray};
	background-color: ${p => p.theme.backgroundGray};
	display: flex;
	justify-content: space-between;
	padding: 16px 40px 0px;
`;

export const ReceiptContent = styled.div`
	padding: 18px 24px 18px;
`;

export const PlaceholderDarkInput = styled(Form.Item)`
	input {
		&::placeholder {
			color: ${p => p.theme.textSecondary};
			opacity: 1;
		}
	}
`;
