import { Col, Row, Spin } from 'antd';
import { TextL, TextM, TextS } from 'components/Text';
import { useTheme } from 'styled-components';
import CelcoinLogo from 'components/Icons/CelcoinLogo';
import dayjs from 'dayjs';
import {
	formatCurrency,
	normalizeCnpj,
	normalizeCpfCnpj,
} from 'helpers/normalizers';
import { DescriptionsVertical } from 'components/DescriptionsVertical';
import { BankListDescription } from 'constants/banksList';
import {
	DescriptionsContainer,
	ReceiptContent,
	ReceiptFooter,
} from './PixReceipt.styles';
import { Modal } from 'components/Modal';
import { useQuery } from '@tanstack/react-query';
import { Navigate, useParams } from 'react-router-dom';
import { AccountsService } from 'modules/escrow/services';
import { EscrowRoutes } from 'modules/escrow/constants/routes';
import { useIsEscrowAccountRoute } from 'modules/escrow/utils/adminRoute';
import { SplashScreen } from 'components/SplashScreen';
import { BankListCode } from 'constants/bankListCode';
import { Account } from 'types/Account';

interface IPostingCreationReceiptProps {
	isOpen: boolean;
	pixId: string;
	account: Account;
	onClose: () => void;
}
const PixReceipt = ({
	isOpen,
	pixId,
	account,
	onClose,
}: IPostingCreationReceiptProps) => {
	const theme = useTheme();
	const { id: entityId } = useParams<{ id: string }>();
	const isEscrowRoute = useIsEscrowAccountRoute();

	const { data: pix, isLoading } = useQuery({
		queryKey: ['getPixCharge', pixId],
		queryFn: () => AccountsService.getPixCharge(entityId!, pixId!),
	});

	if (!pix && !isLoading) {
		return (
			<Navigate
				to={
					isEscrowRoute
						? EscrowRoutes.ESCROW_BASE
						: EscrowRoutes.ACCOUNT_BASE
				}
			/>
		);
	}

	return (
		<Modal
			title={
				<div style={{ padding: '0px 16px' }}>
					<CelcoinLogo color={theme.white} />
				</div>
			}
			isOpen={isOpen}
			width={595}
			rounded={false}
			onClose={onClose}
			style={{
				padding: '0px',
			}}
		>
			{isLoading && <Spin fullscreen />}
			<ReceiptContent>
				<Row gutter={[16, 16]} style={{ textAlign: 'center' }}>
					<Col span={24}>
						<TextL>Comprovante de Cobrança Pix</TextL>
						<TextM weight="normal" color={theme.textGray}>
							{`${dayjs().format('DD/MM/YYYY')} às ${dayjs().format('HH:mm')}`}
						</TextM>
					</Col>
				</Row>
				<Row
					gutter={[16, 16]}
					style={{ textAlign: 'center', paddingTop: '1rem' }}
				>
					<Col span={24}>
						<TextM weight="normal" color={theme.textGray}>
							Valor
						</TextM>
						<TextL color={theme.primary}>
							{formatCurrency(parseFloat(pix?.amount || '0'))}
						</TextL>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ paddingTop: '1.2rem' }}>
					<Col span={24}>
						<DescriptionsContainer>
							<DescriptionsVertical
								hideActions={true}
								title={<TextM>Conta de origem</TextM>}
								items={[
									{
										key: '1',
										label: 'Titular',
										contentStyle: {
											textAlign: 'right',
											color: theme.textGray,
										},
										children: account.person?.name ?? '-',
									},
									{
										key: '2',
										label: 'CNPJ',
										contentStyle: {
											textAlign: 'right',
											color: theme.textGray,
										},
										children: account.person?.taxpayer_id
											? normalizeCnpj(
													account.person.taxpayer_id,
												)
											: '-',
									},
									{
										key: '3',
										label: 'Código do banco',
										contentStyle: {
											textAlign: 'right',
											color: theme.textGray,
										},
										children: `${BankListCode['13935893']} - ${BankListDescription['13935893']}`,
									},
									{
										key: '4',
										label: 'Agência',
										contentStyle: {
											textAlign: 'right',
											color: theme.textGray,
										},
										children: account.branch ?? '-',
									},
									{
										key: '5',
										label: 'Conta',
										contentStyle: {
											textAlign: 'right',
											color: theme.textGray,
										},
										children: account.account ?? '-',
									},
								]}
							/>
						</DescriptionsContainer>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ paddingTop: '1rem' }}>
					<Col span={24}>
						<DescriptionsContainer>
							<DescriptionsVertical
								hideActions={true}
								title={<TextM>Cobrança</TextM>}
								items={[
									// Fora do escopo no momento
									// {
									// 	key: '1',
									// 	label: 'Titular',
									// 	contentStyle: {
									// 		textAlign: 'right',
									// 		color: theme.textGray,
									// 	},
									// 	children:
									// 		pix?.debtor?.name ??
									// 		'Nome não informado',
									// },
									{
										key: '2',
										label: 'CPF/CNPJ',
										contentStyle: {
											textAlign: 'right',
											color: theme.textGray,
										},
										children: pix?.debtor.taxpayer_id
											? normalizeCpfCnpj(
													pix?.debtor.taxpayer_id,
												)
											: '-',
									},
									{
										key: '3',
										label: 'Valor',
										contentStyle: {
											textAlign: 'right',
											color: theme.textGray,
										},
										children: formatCurrency(
											parseFloat(pix?.amount || '0'),
										),
									},
									{
										key: '4',
										label: 'Data do pagamento',
										contentStyle: {
											textAlign: 'right',
											color: theme.textGray,
										},
										children:
											dayjs(pix?.updated_at).format(
												'DD/MM/YYYY',
											) || '-',
									},
								]}
							/>
						</DescriptionsContainer>
					</Col>
				</Row>
			</ReceiptContent>
			<ReceiptFooter>
				<div>
					<TextS color={theme.textGray}>
						Celcoin Instituição de Pagamento S.A
					</TextS>
					<TextS
						color={theme.textGray}
						style={{ textAlign: 'right' }}
					>
						13.935.893/0001-09
					</TextS>
				</div>
			</ReceiptFooter>
		</Modal>
	);
};

export default PixReceipt;
