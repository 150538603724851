import { Collapse as CollapseAnt } from 'antd';
import styled, { css } from 'styled-components';

export const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
`;

export const DescriptionItem = styled.p<{ color?: string }>`
	padding: 0;
	margin: 0 0;
	font-size: 1.2rem;
	display: inline;
	vertical-align: sub;
	color: ${p => p.color || p.theme.text};
`;

export const CollapseExpander = styled.div<{ isActive?: boolean }>`
	transition: transform 0.3s;
	transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : 'none')};
`;

export const CollapseHeader = styled.div`
	display: flex;
	align-items: center;
	p {
		font-size: 18px;
		font-weight: 500;
		margin-left: 8px;
		color: ${({ theme }) => theme.text};
	}
`;

export const Collapse = styled(CollapseAnt)<{ noPadding?: boolean }>`
	border: none;
	background-color: transparent;

	.ant-collapse-item {
		border-bottom: 1px solid ${({ theme }) => theme.border} !important;
		margin-bottom: 1rem;
		border: 1px solid ${({ theme }) => theme.border};
		border-radius: 10px !important;
	}
	.ant-collapse-header {
		height: 51px !important;
		background-color: ${({ theme }) => theme.primaryOpacity};
		border-radius: 10px 10px 0 0 !important;
	}

	${p =>
		p.noPadding &&
		css`
			.ant-collapse-content-box {
				padding: 0px !important;
			}
		`}
`;
