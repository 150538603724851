import { getInstance } from './instances';
import { Auth } from 'aws-amplify';
import { AxiosProgressEvent } from 'axios';
import {
	ACCESS_COMPANY_ID,
	ACCESS_FINANCIAL_INSTITUTION_ID,
} from 'modules/core/constants/session';

const getCurrentAccessToken = async (): Promise<string | undefined> => {
	try {
		return (await Auth.currentSession()).getAccessToken().getJwtToken();
	} catch (e) {
		return undefined;
	}
};

const bindQueryParams = <T>(params?: T): string => {
	if (!params) return '';
	const queryParams = Object.keys(params).reduce((q, paramKey) => {
		const paramValue = params[paramKey as keyof T];
		if (paramValue === undefined || paramValue === null) return q;
		if (Array.isArray(paramValue))
			return `${q}&${paramKey}=${paramValue.join(',')}`;
		return `${q}&${paramKey}=${paramValue}`;
	}, '');
	return `?${queryParams.toString()}`;
};

const getContext = (): string | null => {
	const financialInstitution = sessionStorage.getItem(
		ACCESS_FINANCIAL_INSTITUTION_ID,
	);
	const company = sessionStorage.getItem(ACCESS_COMPANY_ID);

	if (!financialInstitution && !company) return null;

	return btoa(
		JSON.stringify(
			typeof company === 'string'
				? {
						financialInstitutionId: financialInstitution,
						personId: company,
					}
				: {
						financialInstitutionId: financialInstitution,
					},
		),
	);
};

const getHeaders = async (): Promise<{ [key: string]: string }> => {
	const context = getContext();
	const token = await getCurrentAccessToken();

	if (!token && !context) return {};

	if (!context)
		return {
			Authorization: `Bearer ${token}`,
		};

	return {
		Authorization: `Bearer ${token}`,
		'x-context': context,
	};
};

export class NetworkApi {
	public static async get<T, P = unknown>(
		url: string,
		params?: P,
	): Promise<T> {
		const instance = getInstance(window.location.pathname);
		return instance
			.get<T>(`${url}${bindQueryParams<P>(params)}`, {
				headers: await getHeaders(),
			})
			.then(response => response.data)
			.catch(error => Promise.reject(error?.response));
	}

	public static async getWithoutContext<T, P = unknown>(
		url: string,
		params?: P,
	): Promise<T> {
		const instance = getInstance(window.location.pathname);
		const token = await getCurrentAccessToken();
		return instance
			.get<T>(`${url}${bindQueryParams<P>(params)}`, {
				headers: token ? { Authorization: `Bearer ${token}` } : {},
			})
			.then(response => response.data)
			.catch(error => Promise.reject(error?.response));
	}

	public static async post<T, P>(
		url: string,
		params: P,
		onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
	): Promise<T> {
		const instance = getInstance(window.location.pathname);
		return instance
			.post<T, any, P>(url, params, {
				headers: await getHeaders(),
				onUploadProgress,
			})
			.then(response => response.data)
			.catch(error => Promise.reject(error?.response));
	}

	public static async put<T, P>(url: string, params: P): Promise<T> {
		const instance = getInstance(window.location.pathname);
		return instance
			.put<T, any, P>(url, params, {
				headers: await getHeaders(),
			})
			.then(response => response.data)
			.catch(error => Promise.reject(error?.response));
	}

	public static async patch<T, P>(url: string, params: P): Promise<T> {
		const instance = getInstance(window.location.pathname);
		return instance
			.patch<T, any, P>(url, params, {
				headers: await getHeaders(),
			})
			.then(response => response.data)
			.catch(error => Promise.reject(error?.response));
	}

	public static async delete<T, P = {}>(url: string, params?: P): Promise<T> {
		const instance = getInstance(window.location.pathname);
		return instance
			.delete<T>(url, {
				headers: await getHeaders(),
				...(params && { data: params }),
			})
			.then(response => response.data)
			.catch(error => Promise.reject(error?.response));
	}

	public static async upload<T>(
		url: string,
		file: File,
		headers?: { [key: string]: string },
		onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
	): Promise<T> {
		const instance = getInstance(window.location.pathname);
		return instance
			.put<T>(url, file, {
				headers: {
					...(headers ?? {}),
					// 'Content-Type': 'multipart/form-data',
				},
				onUploadProgress,
			})
			.then(response => response.data)
			.catch(error => Promise.reject(error?.response));
	}
}
