import { Button, Flex, Modal as ModalAnt } from 'antd';
import { Content, Header } from './styles';
import { Heading2 } from 'components/Heading2';
import { IoCloseSharp } from 'react-icons/io5';
import React, { ReactNode } from 'react';
import theme from 'theme/theme';
import { TextS } from 'components/Text';

interface IModalProps {
	isOpen: boolean;
	children: ReactNode;
	title: string | ReactNode;
	width?: number;
	height?: number;
	rounded?: boolean;
	style?: React.CSSProperties;
	contentStyle?: React.CSSProperties;
	onClose?: () => void;
	subtitle?: string;
}
const Modal = ({
	isOpen,
	children,
	title,
	height,
	width = 600,
	rounded = true,
	style,
	contentStyle,
	onClose,
	subtitle,
}: IModalProps) => {
	return (
		<ModalAnt
			title={
				<Header rounded={rounded}>
					{typeof title === 'string' ? (
						<Heading2>{title}</Heading2>
					) : (
						title
					)}
					<Flex align="center">
						{subtitle && (
							<TextS
								weight="lighter"
								style={{ marginRight: '10px' }}
								color={theme.white}
							>
								{subtitle}
							</TextS>
						)}
						{typeof onClose === 'function' && (
							<Button
								type="text"
								icon={
									<IoCloseSharp
										size={24}
										color={theme.white}
									/>
								}
								onClick={onClose}
							/>
						)}
					</Flex>
				</Header>
			}
			styles={{
				header: {
					backgroundColor: theme.primary,
					borderTopLeftRadius: rounded ? '15px' : 0,
					borderTopRightRadius: rounded ? '15px' : 0,
					borderBottomLeftRadius: 0,
					borderBottomRightRadius: 0,
					marginBottom: '0px',
				},
				content: {
					padding: 0,
					margin: 0,
					minHeight: height,
					borderTopLeftRadius: rounded ? '15px' : 0,
					borderTopRightRadius: rounded ? '15px' : 0,
					borderBottomLeftRadius: rounded ? '15px' : 0,
					borderBottomRightRadius: rounded ? '15px' : 0,
					...(contentStyle ?? {}),
				},
			}}
			closeIcon={null}
			centered
			open={isOpen}
			footer={null}
			destroyOnClose
			onCancel={onClose}
			width={width}
		>
			<Content
				style={{
					minHeight: height,
					...(style ?? {}),
				}}
			>
				{children}
			</Content>
		</ModalAnt>
	);
};

export default Modal;
