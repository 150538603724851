import { Button, Spin, notification } from 'antd';
import { StyledContent, StyledFooter } from './styles';
import { TextM } from 'components/Text';
import theme from 'theme/theme';
import { Modal } from 'components/Modal';
import { useCallback, useState } from 'react';
import { ArrowRightIcon } from 'components/Icons/ArrowRight';
import DebtorForm from './DebtorForm/DebtorForm';
import OperationForm from './OperationForm/OperationForm';
import {
	ICreatePixForm,
	PixChargeDebtorType,
	PixChargeDiscountType,
	PixChargeFineType,
	PixChargeInterestType,
	TCreatePixPayload,
} from '../types';
import {
	isPersonOrCompany,
	validateCnpj,
	validateCpf,
	validateEmail,
} from 'helpers/validators';
import { EClientType } from 'types/Company';
import { SelectType } from 'types/Management';
import { AccountsService } from 'modules/escrow/services';
import { Navigate, useParams } from 'react-router-dom';
import { queryClient } from 'network/query';
import dayjs from 'dayjs';
import { serializeOnlyNumbers } from 'helpers/serializers';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { EscrowRoutes } from 'modules/escrow/constants/routes';
import { useIsEscrowAccountRoute } from 'modules/escrow/utils/adminRoute';

export const enum ECreationSteps {
	DEBTOR = 'DEBTOR',
	OPERATION = 'OPERATION',
}

interface ICreatePixModalProps {
	closeModal: () => void;
	pixCreated: (id: string) => void;
}

const CreatePixModal = ({ closeModal, pixCreated }: ICreatePixModalProps) => {
	const [api, contextHolder] = notification.useNotification();
	const { id: entityId } = useParams<{ id: string }>();
	const isEscrowRoute = useIsEscrowAccountRoute();

	const [pixCharge, setPixCharge] = useState<ICreatePixForm>(
		{} as ICreatePixForm,
	);
	const [currentStep, setCurrentStep] = useState<ECreationSteps>(
		ECreationSteps.DEBTOR,
	);

	if (!entityId) {
		return (
			<Navigate
				to={
					isEscrowRoute
						? EscrowRoutes.ESCROW_BASE
						: EscrowRoutes.ACCOUNT_BASE
				}
			/>
		);
	}

	const { mutate: createPixCharge, isPending: isLoading } = useMutation<
		{ id: string },
		ApiError,
		TCreatePixPayload
	>({
		mutationFn: payload => {
			return AccountsService.createPixCharge(entityId, payload);
		},
		onSuccess: ({ id }) => {
			pixCreated(id);

			queryClient.refetchQueries({
				queryKey: ['getPixCharges', entityId],
			});

			return api.success({
				message: 'Cobrança Pix criada com sucesso.',
			});
		},
		onError: e => {
			api.error({
				description: e?.data?.message || '',
				message: 'Houve um erro ao criar sua cobrança Pix.',
			});
		},
	});

	const nextStepHandler = useCallback(() => {
		const isDocValid =
			isPersonOrCompany(pixCharge.taxpayerId) === EClientType.PJ
				? validateCnpj(pixCharge.taxpayerId)
				: validateCpf(pixCharge.taxpayerId);
		const isEmailValid = !!pixCharge.emailAddress
			? validateEmail(pixCharge.emailAddress)
			: true;

		if (!isDocValid) {
			return api.error({
				message: 'Insira um documento válido.',
			});
		}

		if (!isEmailValid) {
			return api.error({
				message: 'Insira um e-mail válido.',
			});
		}

		if (currentStep === ECreationSteps.DEBTOR) {
			return pixCharge.taxpayerId
				? setCurrentStep(ECreationSteps.OPERATION)
				: api.error({
						message: 'Insira um documento antes de prosseguir.',
					});
		}

		if (
			currentStep === ECreationSteps.OPERATION &&
			(!pixCharge?.amount ||
				!pixCharge.dueDate ||
				!pixCharge.expirationAfterPayment)
		) {
			return api.error({
				message: 'Verifique o preenchimento dos dados obrigatórios.',
			});
		}

		if (
			currentStep === ECreationSteps.OPERATION &&
			pixCharge.fineType === SelectType.FIXED &&
			pixCharge?.fineAmount &&
			pixCharge?.fineAmount >= pixCharge.amount
		) {
			return api.error({
				message:
					'O valor da multa não pode ser maior que o valor da cobrança.',
			});
		}

		if (
			currentStep === ECreationSteps.OPERATION &&
			pixCharge.discountType === SelectType.FIXED &&
			pixCharge?.discountAmount &&
			pixCharge?.discountAmount >= pixCharge.amount
		) {
			return api.error({
				message:
					'O valor de desconto deve ser menor que o valor da cobrança',
			});
		}

		const paymentDate = dayjs(pixCharge.dueDate);
		const limitPaymentDate = dayjs(pixCharge.expirationAfterPayment);
		const parseDate = limitPaymentDate.diff(paymentDate, 'day');

		const payload: TCreatePixPayload = {
			amount: pixCharge.amount,
			debtor: {
				name: pixCharge?.name || 'Não informado',
				taxpayerId: serializeOnlyNumbers(pixCharge.taxpayerId),
				type:
					isPersonOrCompany(pixCharge.taxpayerId) === EClientType.PJ
						? PixChargeDebtorType.COMPANY
						: PixChargeDebtorType.PERSON,
				address: pixCharge.address,
				emailAddress: pixCharge.emailAddress,
			},
			dueDate: dayjs(pixCharge.dueDate).format('YYYY-MM-DD'),
			expirationAfterPayment: parseDate,
			interestAmount: pixCharge.interestAmount || 0,
			interestType:
				pixCharge.interestType ||
				PixChargeInterestType.PERCENTAGE_PER_MONTH_CALENDAR_DAYS,
			description: pixCharge?.description,
			discountDatesSettings: !!pixCharge?.discountAmount
				? {
						discountType:
							pixCharge.discountType === SelectType.FIXED
								? PixChargeDiscountType.FIXED_VALUE_UNTIL_THE_DATES_INFORMED
								: PixChargeDiscountType.PERCENTAGE_DATE_REPORTED,
						settings: [
							{
								amount: pixCharge?.discountAmount || 0,
								date:
									dayjs(pixCharge.discountDate).format(
										'YYYY-MM-DD',
									) || '',
							},
						],
					}
				: undefined,
			fineAmount: pixCharge?.fineAmount || undefined,
			fineType: !!pixCharge.fineType
				? pixCharge.fineType === SelectType.FIXED
					? PixChargeFineType.FIXED_VALUE
					: PixChargeFineType.PERCENT
				: undefined,
		};

		createPixCharge(payload);
	}, [pixCharge, currentStep]);

	const backStepHandler = () => {
		if (currentStep === ECreationSteps.DEBTOR) {
			return closeModal();
		}

		setCurrentStep(ECreationSteps.DEBTOR);
	};

	const steps = {
		[ECreationSteps.DEBTOR]: (
			<DebtorForm pixCharge={pixCharge} setPixCharge={setPixCharge} />
		),
		[ECreationSteps.OPERATION]: (
			<OperationForm pixCharge={pixCharge} setPixCharge={setPixCharge} />
		),
	};

	return (
		<>
			<Modal
				isOpen
				title="Cobrar com pix"
				onClose={closeModal}
				subtitle={
					currentStep === ECreationSteps.DEBTOR ? '1 de 2' : '2 de 2'
				}
			>
				{contextHolder}

				{isLoading && <Spin fullscreen />}
				<StyledContent>{steps[currentStep]}</StyledContent>

				{/* Footer */}
				<StyledFooter>
					<Button
						type="text"
						onClick={backStepHandler}
						style={{ marginRight: '1rem' }}
						disabled={isLoading}
					>
						<TextM color={theme.primary}>
							{currentStep === ECreationSteps.DEBTOR
								? 'Cancelar'
								: 'Voltar'}
						</TextM>
					</Button>
					<Button
						type="primary"
						onClick={nextStepHandler}
						disabled={isLoading}
					>
						{currentStep === ECreationSteps.DEBTOR ? (
							<TextM
								color={isLoading ? theme.primary : theme.white}
							>
								Avançar{' '}
								{isLoading ? (
									<Spin size="small" />
								) : (
									<ArrowRightIcon color={theme.white} />
								)}
							</TextM>
						) : (
							<TextM color={theme.white}>Gerar cobrança</TextM>
						)}
					</Button>
				</StyledFooter>
			</Modal>
		</>
	);
};

export default CreatePixModal;
