import { Col, Flex, Form, Input, Row, Switch, notification } from 'antd';
import { ICreatePixForm } from '../../types';
import FormItem from 'antd/es/form/FormItem';
import { normalizeCpfCnpj } from 'helpers/normalizers';
import { cpfCnpjRule, emailRule } from 'helpers/rules';
import { TextL, TextS } from 'components/Text';
import theme from 'theme/theme';
import { useState } from 'react';
import AddressContainer from 'components/Address/Address';
import { Address } from 'types/Address';

interface IDebtorFormProps {
	pixCharge: ICreatePixForm;
	setPixCharge: (values: ICreatePixForm) => void;
}

const DebtorForm = ({ pixCharge, setPixCharge }: IDebtorFormProps) => {
	/* Hooks */
	const [form] = Form.useForm();
	const [api, contextHolder] = notification.useNotification();

	/* States */
	const [isOpenAddressForm, setIsOpenAddressForm] = useState<boolean>(
		!!pixCharge?.address?.postal_code,
	);

	return (
		<>
			{contextHolder}
			<TextL
				style={{ alignSelf: 'flex-start', marginBottom: '1rem' }}
				color={theme.textSecondary}
			>
				Devedor
			</TextL>

			<Form<ICreatePixForm>
				form={form}
				layout="vertical"
				initialValues={pixCharge}
				onFieldsChange={f => {
					setPixCharge({ ...pixCharge, [f[0].name]: f[0].value });
				}}
				style={{ width: '100%' }}
			>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<FormItem style={{ padding: 0, margin: 0 }}>
							<Form.Item
								name="taxpayerId"
								label="CPF / CNPJ"
								normalize={e => normalizeCpfCnpj(e)}
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
									cpfCnpjRule,
								]}
							>
								<Input placeholder="000.000.000-00" />
							</Form.Item>
						</FormItem>
					</Col>
					<Col span={24}>
						<Form.Item
							name="emailAddress"
							label="E-mail para contato (opcional)"
							rules={[emailRule('email_address')]}
						>
							<Input placeholder="exemplo@exemplo.com.br" />
						</Form.Item>
					</Col>
				</Row>

				<Flex
					align="center"
					justify="space-between"
					style={{ margin: '1rem 0' }}
				>
					<TextS color={theme.textSecondary}>
						Inserir endereço? (opcional)
					</TextS>
					<Switch
						checked={isOpenAddressForm}
						onChange={() => {
							setPixCharge({
								...pixCharge,
								address: {} as Address,
							});
							setIsOpenAddressForm(!isOpenAddressForm);
						}}
					/>
				</Flex>

				{isOpenAddressForm && (
					<AddressContainer
						address={pixCharge?.address || ({} as Address)}
						hideTitle={true}
						setAddress={address =>
							setPixCharge({
								...pixCharge,
								address: address,
							})
						}
					/>
				)}
			</Form>
		</>
	);
};

export default DebtorForm;
