import { AccountFee, AccountFeeMode, AccountFeeType } from 'types/Account';
import { Destination, DestinationDto } from 'types/Destination';
import { IListFilters } from 'types/List';
import { PaymentType } from 'types/Posting';

export enum TemplateType {
	SIGNATURE_METHOD_ESCROW = 'SIGNATURE_METHOD_ESCROW',
	SIGNATURE_METHOD_PAYMENT = 'SIGNATURE_METHOD_PAYMENT',
	FEE_AND_COMMISSION_ESCROW = 'FEE_AND_COMMISSION_ESCROW',
	FEE_AND_COMMISSION_PAYMENT = 'FEE_AND_COMMISSION_PAYMENT',
	ESCROW_ACCOUNT_AGREEMENT = 'ESCROW_ACCOUNT_AGREEMENT',
	PAYMENT_ACCOUNT_AGREEMENT = 'PAYMENT_ACCOUNT_AGREEMENT',
	SKIP_AGREEMENT_ESCROW = 'SKIP_AGREEMENT_ESCROW',
	SKIP_AGREEMENT_PAYMENT = 'SKIP_AGREEMENT_PAYMENT',
}

export enum ClicksignSignatureMethodType {
	EMAIL_TOKEN = 'email',
	SELFIE = 'selfie',
	ICP_CERTIFICATE = 'icp_brasil',
}

export interface ITemplateConfigEscrowAgreement {
	template_type: TemplateType;
	file_key: string;
	file_url: string;
	person_id?: string;
	file_name?: string;
	id?: string;
}

export interface ITemplateConfigEscrowAgreementPayload {
	templateType: TemplateType;
	fileKey: string;
	fileName?: string;
	personId?: string;
}

export interface ITemplateConfigPaymentAgreement
	extends ITemplateConfigEscrowAgreement {}

export interface ITemplateConfigAccountFeePayload {
	beneficiary?: {
		taxpayerId: string;
		name: string;
		bank: string;
		branch: string;
		account: string;
		type: string;
	};
	mode: AccountFeeMode;
	defaultPaymentType: PaymentType;
	// TARIFA DA CONTA:
	maintenance: {
		day?: number;
		amount?: number;
		commissionPercentAmount?: number;
	};
	// ENTRADA:
	deposit: {
		amount?: number;
		amountType?: AccountFeeType;
	};
	// PIX SAIDA:
	withdrawal?: {
		amount?: number;
		amountType?: AccountFeeType;
		commissionPercentAmount?: number;
	};
	// TED SAIDA:
	tedTransfer: {
		amount?: number;
		amountType?: AccountFeeType;
		commissionPercentAmount?: number;
	};
	// TARIFA DE BOLETO:
	chargeSettlement: {
		amount?: number;
		amountType?: AccountFeeType;
		commissionPercentAmount?: number;
	};
}

export interface ITemplateConfigAccountFee {
	beneficiary?: {
		taxpayer_id: string;
		name: string;
		bank: string;
		branch: string;
		account: string;
		type: string;
	};
	mode: AccountFeeMode;
	default_payment_type: PaymentType;
	// TARIFA DA CONTA:
	maintenance?: {
		day?: number;
		amount?: number;
		commission_percent_amount?: number;
	};
	// PIX ENTRADA:
	deposit?: {
		amount_type?: AccountFeeType;
		amount?: number;
	};
	// PIX SAIDA:
	withdrawal?: {
		amount_type?: AccountFeeType;
		amount?: number;
		commission_percent_amount?: number;
	};
	// TED SAIDA:
	ted_transfer?: {
		amount_type?: AccountFeeType;
		amount?: number;
		commission_percent_amount?: number;
	};
	// TARIFA DE BOLETO:
	charge_settlement?: {
		amount_type?: AccountFeeType;
		amount?: number;
		commission_percent_amount?: number;
	};
	// TARIFA DE COBRANÇA PIX:
	charge_pix?: {
		amount_type?: AccountFeeType;
		amount?: number;
		commission_percent_amount?: number;
	};
	// TARIFA DE EMISSÃO DE BOLETO:
	charge_issuance?: {
		amount_type?: AccountFeeType;
		amount?: number;
		commission_percent_amount?: number;
	};
}

export interface ITemplateConfigCommission {
	id?: string;
	template_type: TemplateType;
	person_id?: string;
	configuration_payload: ITemplateConfigAccountFee;
}

export interface ITemplateConfigCommissionPayload {
	templateType: TemplateType;
	personId?: string;
	configurationPayload: ITemplateConfigAccountFee;
}

export interface ITemplateConfigSignaturePayload {
	personId?: string;
	templateType: TemplateType;
	configurationPayload: {
		methods: ClicksignSignatureMethodType[];
	};
}

export interface ITemplateConfigSignature {
	id?: string;
	person_id?: string;
	template_type: TemplateType;
	configuration_payload: {
		methods: ClicksignSignatureMethodType[];
	};
}

export interface ITemplateSkipSignatureContract {
	id?: string;
	person_id?: string;
	template_type: TemplateType;
	configuration_payload: {
		enabled: boolean;
	};
}

export interface ITemplateSkipSignatureContractPayload {
	id?: string;
	personId?: string;
	templateType: TemplateType;
	configurationPayload: {
		enabled: boolean;
	};
}

/* @Deprecated */
// export interface ITemplateConfigTenantType {
// 	person_id?: string;
// 	template_type: TemplateType;
// 	id?: string;
// 	configuration_payload: {
// 		tenant: ETenantType;
// 	};
// }

/* @Deprecated */
// export interface ITemplateConfigTenantTypePayload {
// 	personId?: string;
// 	templateType: TemplateType;
// 	id?: string;
// 	configurationPayload: {
// 		tenant: ETenantType;
// 	};
// }

export interface ITemplateConfigListParams extends IListFilters {
	person_id?: string;
}

export interface ITemplateConfig {
	id: string;
	template_type: string;
	file_key: string | null;
	person_id: string | null;
	financial_institution_id: string;
	configuration_payload: any | null;
}

export interface IAccountFeeParsed {
	chargeSettlement?: number;
	chargePix?: number;
	chargeIssuance?: number;
	commissionAccountAccount?: string;
	commissionAccountBank?: string;
	commissionAccountBranch?: string;
	commissionAccountDestinationId?: string;
	commissionAccountName?: string;
	commissionAccountTaxpayerId?: string;
	commissionAccountType?: string;
	commissionChargeSettlementAmount?: number;
	commissionChargePixAmount?: number;
	commissionChargeIssuanceAmount?: number;
	commissionMaintenanceAmount?: number;
	commissionTedTransferAmount?: number;
	commissionWithdrawalAmount?: number;
	deposit?: number;
	depositType?: string;
	feeMode?: string;
	maintenanceAmount?: number;
	maintenanceDay?: number;
	pixDebit?: number;
	pixDebitType?: string;
	tedDebit?: number;
	tedDebitType?: string;
}

export interface ICreateUpdateConfigProps {
	fees?: AccountFee | IAccountFeeParsed;
	file?: File;
	method: TemplateType;
	destination?: DestinationDto | Destination;

	/* @Deprecated */
	// tenantType?: ETenantType;
}
