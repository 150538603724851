import { ReactNode, useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
	Button,
	Card,
	Col,
	Divider,
	Flex,
	Row,
	Table,
	notification,
} from 'antd';

// Components
import { PageHeader } from 'components/PageHeader';
import EscrowAccountInfo from '../components/EscrowAccountInfoHeader/EscrowAccountInfoHeader';
import { SplashScreen } from 'components/SplashScreen';
import { Heading1 } from 'components/Heading1';
import { EscrowAccountOutlinedIcon } from 'components/Icons/EscrowAccount';
import { TextM, TextS } from 'components/Text';
import { PaymentCreation } from 'modules/escrow/components/PaymentCreation';
import { PostingCreation } from 'modules/escrow/components/PostingCreation';
import { Heading2 } from 'components/Heading2';
import { PostingIcon } from 'components/Icons/Posting';
import { PaperIcon } from 'components/Icons/Paper';
import { BarCodeIcon } from 'components/Icons/BarCode';

// Hooks and services
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccountsService } from 'modules/escrow/services';
import {
	getAccountRouteTypeIcon,
	getAccountRouteTypeName,
	useIsEscrowAccountRoute,
} from 'modules/escrow/utils/adminRoute';
import { AbilityContext } from 'modules/core/context/AbilityContext';
import { useAccount } from 'modules/escrow/hooks/useAccount';

// Types and helpers
import { EscrowRoutes, contaLivre } from 'modules/escrow/constants/routes';
import { PersonType } from 'types/Company';
import { AccountStatementMovement, AccountStatus } from 'types/Account';
import { fixPhraseFromBaas } from 'helpers/validators';
import { getStatementMovementType } from 'modules/escrow/utils/statementDescription';
import { formatCurrency } from 'helpers/normalizers';
import { List } from 'types/List';
import { ApiError } from 'types/ApiError';
import { AccessType } from 'types/Access';

// Styles
import theme from 'theme/theme';
import { IoMdArrowForward } from 'react-icons/io';
import { StyledCard, StyledMenuContainer } from './EscrowAccountMenu.styles';
import { BiMoneyWithdraw } from 'react-icons/bi';
import { subject } from '@casl/ability';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { EscrowBalance } from '../EscrowAccounts/styles';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import { BreadcrumbContainer } from 'components/Breadcrumb';

const EscrowAccountMenu = () => {
	const { id } = useParams();
	const [api, contextHolder] = notification.useNotification();
	const isEscrowRoute = useIsEscrowAccountRoute();
	const ability = useContext(AbilityContext);
	const { type } = useCurrentProfile();
	const navigate = useNavigate();
	const { setBreadcrumb } = useBreadcrumb();

	const [isPostingCreationOpen, setIsPostingCreationOpen] = useState(false);
	const [isPaymentCreationOpen, setIsPaymentCreationOpen] = useState(false);
	const [visibleBalances, setVisibleBalances] = useState<{
		[key: string]: number;
	}>({});

	const { data, isLoading } = useQuery({
		queryKey: ['accountDetails', id],
		queryFn: () => AccountsService.getAccount(id!),
		refetchOnWindowFocus: false,
		enabled: typeof id === 'string',
	});

	const { data: statement, isLoading: isLoadingStatement } = useQuery<
		List<AccountStatementMovement>,
		ApiError
	>({
		queryKey: ['accountStatement'],
		queryFn: () =>
			AccountsService.getStatement(id || '', {
				date_from: dayjs().subtract(10, 'day').format('YYYY-MM-DD'),
				date_to: dayjs().format('YYYY-MM-DD'),
				page: 1,
				size: 15,
			}),
		staleTime: 5000,
	});

	const { isBalanceLoading, getAccountBalance } = useAccount({
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema.',
			});
		},
		onBalanceSuccess: (account, amount) => {
			setVisibleBalances(prev => ({
				...prev,
				[account.id]: amount,
			}));
		},
	});

	const redirectAccount = isEscrowRoute
		? EscrowRoutes.ESCROW_BASE
		: EscrowRoutes.ACCOUNT_BASE;
	const title = (
		<BreadcrumbContainer>
			{getAccountRouteTypeIcon()}
			<span>{getAccountRouteTypeName()}</span>
		</BreadcrumbContainer>
	);

	const accountRoute = !useIsEscrowAccountRoute() ? contaLivre : 'escrow';

	const renderingCard = (button: {
		label: string;
		icon: ReactNode;
		onClick: () => void;
	}) => {
		return (
			<Col span={8} onClick={button.onClick}>
				<StyledCard>
					<Card>
						<div className="cardIcon">{button.icon}</div>
						<Flex align="center" justify="space-between">
							<TextM>{button.label}</TextM>
							<div className="hoverArrowIcon">
								<IoMdArrowForward size={18} />
							</div>
						</Flex>
					</Card>
				</StyledCard>
			</Col>
		);
	};

	const isVisible = typeof visibleBalances[data?.id || ''] === 'number';

	const columns: ColumnsType<AccountStatementMovement> = [
		{
			title: 'Data',
			render: (item: AccountStatementMovement) => {
				if (item.id.includes('daily-balance')) {
					return (
						<TextS
							style={{
								color: theme.primary,
								fontWeight: 600,
							}}
						>
							Dia {dayjs(item.create_date).format('DD/MM/YYYY')}
						</TextS>
					);
				}

				return (
					<TextS
						style={{
							color: theme.textGray,
						}}
					>
						{dayjs(item.create_date).format('DD/MM/YYYY')} às{' '}
						{dayjs(item.create_date).format('HH:mm:ss')}
					</TextS>
				);
			},
			width: '25%',
		},
		{
			title: 'Lançamento',
			render: (item: AccountStatementMovement) => {
				if (item.id.includes('daily-balance')) {
					return '';
				}

				return (
					<TextS
						style={{
							color: theme.textGray,
						}}
					>
						{getStatementMovementType(item) || '-'}
					</TextS>
				);
			},
			width: '30%',
		},
		{
			title: 'Descrição',
			render: (item: AccountStatementMovement) => {
				if (item.id.includes('daily-balance')) {
					return '';
				}
				return (
					<TextS>
						<pre
							style={{
								color: theme.textGray,
								fontFamily: theme.font.primary,
							}}
						>
							{fixPhraseFromBaas(item.description) || '-'}
						</pre>
					</TextS>
				);
			},
			width: '30%',
		},
		{
			title: 'Valor',
			render: (item: AccountStatementMovement) => {
				if (item.id.includes('daily-balance')) {
					return (
						<TextM
							style={{
								color: theme.primary,
							}}
						>
							{item.balance != null
								? formatCurrency(item.balance!)
								: ''}
						</TextM>
					);
				}

				return (
					<TextM
						style={{
							fontFamily: "'Inter', 'sans-serif'",
							color:
								item.balance_type === 'CREDIT'
									? theme.successHighlight
									: theme.danger,
						}}
					>
						{item.balance_type === ''
							? formatCurrency(item.amount!)
							: item.balance_type === 'CREDIT'
								? `+${formatCurrency(item.amount!)}`
								: formatCurrency(item.amount! * -1)}
					</TextM>
				);
			},
			width: '15%',
		},
	];

	const titleName = data?.person.name || data?.person.corporate_name || '';

	const redirectStatement = () => {
		const isAble = ability.can('VIEW', 'POSTING');

		if (type === AccessType.PERSON && !isAble) {
			return api.info({
				message: 'Você não tem permissão',
				description:
					'Verifique suas permissões para efetuar esta transação.',
			});
		}

		if (data?.status !== AccountStatus.ACTIVE) {
			return api.info({
				message: 'Conta não está ativa',
				description:
					'A conta precisa estar ativa para consultar o extrato',
			});
		}

		navigate(`/${accountRoute}/${id}/statement`);
	};

	useEffect(() => {
		setBreadcrumb([
			{
				href: redirectAccount,
				title: title,
			},
			{
				title: (
					<TextM
						color={theme.textGray}
					>{`Conta ${data?.person?.name || ''}`}</TextM>
				),
			},
		]);
	}, [data, redirectAccount]);

	if (isLoading) {
		return <SplashScreen />;
	}

	if (!data) {
		return <Navigate to={EscrowRoutes.ESCROW_BASE} />;
	}

	return (
		<>
			{contextHolder}
			<PageHeader isFluid={true}>
				<Flex
					align="center"
					justify="space-between"
					style={{
						marginTop: '1.5rem',
						marginRight: '1rem',
						width: '100%',
					}}
				>
					<Flex>
						<EscrowAccountOutlinedIcon
							color={theme.primary}
							size={30}
						/>
						<Heading1 style={{ margin: '0 0 0 10px' }}>
							Conta de {titleName}
						</Heading1>
					</Flex>
					<Flex>
						{[AccountStatus.BLOCKED, AccountStatus.ACTIVE].includes(
							data.status,
						) && (
							<EscrowBalance isVisible={isVisible}>
								<TextM
									weight="bold"
									style={{
										marginRight: '0.6rem',
									}}
								>
									Saldo disponível:
								</TextM>
								<TextM weight="normal" color={theme.textGray}>
									{isVisible
										? formatCurrency(
												visibleBalances[data.id],
											)
										: '*******'}
								</TextM>
								<Button
									type="link"
									size="large"
									icon={
										isVisible ? <BsEyeSlash /> : <BsEye />
									}
									loading={isBalanceLoading === id}
									onClick={() => {
										if (!isVisible) {
											getAccountBalance(data);
											return;
										}
										setVisibleBalances(prev => {
											delete prev[data.id];
											return { ...prev };
										});
									}}
								/>
							</EscrowBalance>
						)}
					</Flex>
				</Flex>
			</PageHeader>
			<Divider style={{ margin: 0, marginTop: '1.5rem' }} />

			{/* Header Info */}
			<EscrowAccountInfo account={data} />

			<StyledMenuContainer>
				{/* Buttons  */}
				<Row gutter={[16, 32]}>
					{renderingCard({
						label: 'Transferir',
						icon: <PostingIcon color={theme.primary} />,
						onClick: () => {
							const isAble =
								type === AccessType.PERSON &&
								ability.can(
									'CREATE',
									subject('POSTING', { accountId: data?.id }),
								);

							if (type === AccessType.PERSON && !isAble) {
								return api.info({
									message: 'Você não tem permissão',
									description:
										'Verifique suas permissões para efetuar esta transação.',
								});
							}

							if (data?.status !== AccountStatus.ACTIVE) {
								return api.info({
									message: 'Conta não está ativa',
									description:
										'A conta precisa estar ativa para realizar transferências',
								});
							}

							setIsPostingCreationOpen(true);
						},
					})}

					{renderingCard({
						label: 'Pagar boleto',
						icon: <BarCodeIcon color={theme.primary} />,
						onClick: () => {
							const isAbleToCreate =
								type === AccessType.PERSON &&
								ability.can(
									'CREATE',
									subject('POSTING', { accountId: data?.id }),
								);

							if (type === AccessType.PERSON && !isAbleToCreate) {
								return api.info({
									message: 'Você não tem permissão',
									description:
										'Verifique suas permissões para efetuar esta transação.',
								});
							}

							if (data?.status !== AccountStatus.ACTIVE) {
								return api.info({
									message: 'Conta não está ativa',
									description:
										'A conta precisa estar ativa para realizar pagamentos',
								});
							}

							setIsPaymentCreationOpen(true);
						},
					})}

					{renderingCard({
						label: 'Extrato',
						icon: (
							<BiMoneyWithdraw color={theme.primary} size={18} />
						),
						onClick: () => redirectStatement(),
					})}

					{renderingCard({
						label: 'Cobrar com Pix',
						icon: <PostingIcon color={theme.primary} />,
						onClick: () => {
							const isAbleToCreate =
								type === AccessType.PERSON &&
								ability.can(
									'CREATE',
									subject('CHARGE', { accountId: data?.id }),
								);

							if (type === AccessType.PERSON && !isAbleToCreate) {
								return api.info({
									message: 'Você não tem permissão',
									description:
										'Verifique suas permissões para efetuar esta transação.',
								});
							}

							navigate(`/${accountRoute}/${id}/pix`);
						},
					})}

					{renderingCard({
						label: 'Minha conta',
						icon: <PaperIcon color={theme.primary} size={18} />,
						onClick: () => {
							const isAbleToCreate =
								type === AccessType.PERSON &&
								ability.can(
									'VIEW',
									subject('ACCOUNT', { accountId: data?.id }),
								);

							if (type === AccessType.PERSON && !isAbleToCreate) {
								return api.info({
									message: 'Você não tem permissão',
									description:
										'Verifique suas permissões para efetuar esta transação.',
								});
							}

							navigate(
								`/${accountRoute}/${id}/${
									data.person.person_type === PersonType.LEGAL
										? 'details'
										: 'natural-person/details'
								}/info`,
							);
						},
					})}
				</Row>

				{/* Last info */}
				{data.status === AccountStatus.ACTIVE && (
					<>
						<Flex
							align="center"
							justify="space-between"
							style={{ marginTop: '3rem' }}
						>
							<Heading2>Últimas movimentações</Heading2>
							<Button type="primary" onClick={redirectStatement}>
								<TextS color={theme.white}>Ver todas</TextS>{' '}
								<IoMdArrowForward size={16} />
							</Button>
						</Flex>
						<Table
							style={{
								marginTop: '1rem',
								overflowY: 'auto',
								maxHeight: '35rem',
							}}
							columns={columns}
							rowKey={record => record.id}
							dataSource={statement?.content}
							loading={isLoadingStatement}
							locale={{
								emptyText:
									'Nenhuma movimentação encontrada nos últimos 10 dias',
							}}
							rowClassName={record => {
								return record.id.includes('daily-balance')
									? `daily-balance ${record.id}`
									: '';
							}}
							pagination={false}
						/>
					</>
				)}
			</StyledMenuContainer>

			{data && (
				<PostingCreation
					isOpen={isPostingCreationOpen}
					account={data}
					onClose={() => {
						setIsPostingCreationOpen(false);
					}}
				/>
			)}
			{isPaymentCreationOpen && (
				<PaymentCreation
					isOpen={isPaymentCreationOpen}
					account={data}
					onClose={() => setIsPaymentCreationOpen(false)}
				/>
			)}
		</>
	);
};

export default EscrowAccountMenu;
