import { Flex, Form, InputNumber, Select } from 'antd';
import { CurrencyInput } from 'components/CurrencyInput';
import { CargeSelectWrapper } from './styles';
import { SelectType } from 'types/Management';
import { TextS } from 'components/Text';
import theme from 'theme/theme';

export interface InputTypeSelectionProps {
	inputSelectType?: SelectType;
	setSelectType: (value: SelectType) => void;
	amount?: number;
	title?: string;
	formItemName: string;
}

const InputTypeSelection = ({
	inputSelectType,
	setSelectType,
	amount,
	title,
	formItemName,
}: InputTypeSelectionProps) => {
	return (
		<>
			{title && (
				<TextS
					color={theme.textSecondary}
					style={{ marginBottom: '8px' }}
				>
					{title}
				</TextS>
			)}
			<Flex>
				<CargeSelectWrapper>
					<Select
						placeholder="Selecione"
						value={inputSelectType}
						style={{
							borderTopRightRadius: 0,
							borderBottomRightRadius: 0,
							width: '15rem',
						}}
						options={[
							{ label: 'Monetário', value: SelectType.FIXED },
							{ label: 'Percentual', value: SelectType.PERCENT },
						]}
						onChange={value => setSelectType(value)}
					/>

					<Form.Item name={formItemName} style={{ width: '100%' }}>
						{inputSelectType === SelectType.PERCENT ? (
							<InputNumber
								min={0}
								max={100}
								style={{
									borderTopLeftRadius: 0,
									borderBottomLeftRadius: 0,
									width: '100%',
								}}
								prefix={'%'}
								decimalSeparator=","
								placeholder="0"
								value={amount}
							/>
						) : (
							<CurrencyInput
								value={amount}
								placeholder="0,00"
								style={{
									borderTopLeftRadius: 0,
									borderBottomLeftRadius: 0,
									width: '100%',
								}}
							/>
						)}
					</Form.Item>
				</CargeSelectWrapper>
			</Flex>
		</>
	);
};

export default InputTypeSelection;
